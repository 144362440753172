import { Form, useActionData } from 'react-router-dom';

function Project() {
    const message = useActionData() as string;

    return (
        <div className="max-w-md m-auto space-y-4">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                Add project
            </h3>
            <p className="text-sm">
                Create a new project based on an existing survey. You can then
                share your survey with participants and collect responses.
            </p>
            <Form className="flex flex-col gap-4 text-sm" method="post">
                <div>
                    <label
                        htmlFor="new-project-name"
                        className="block mb-2 text-base font-medium text-gray-900 dark:text-white"
                    >
                        Name
                    </label>
                    <input
                        id="new-project-name"
                        name="name"
                        className="block p-2.5 w-full text-sm bg-gray-50 focus:outline-none border rounded-lg border-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700/20 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:placeholder-gray-400"
                        defaultValue=""
                    />
                </div>
                <div>
                    <label
                        htmlFor="new-project-survey-uuid"
                        className="block mb-2 text-base font-medium text-gray-900 dark:text-white"
                    >
                        Survey UUID
                    </label>
                    <input
                        id="new-project-survey-uuid"
                        name="survey_uuid"
                        className="block p-2.5 w-full text-sm bg-gray-50 focus:outline-none border rounded-lg border-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700/20 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:placeholder-gray-400"
                        defaultValue=""
                    />
                </div>
                <button
                    type="submit"
                    name="intent"
                    value="add"
                    className="self-end text-white px-8 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                    Add
                </button>
            </Form>
            {message && (
                <div className="bg-red-100 border border-red-400 px-4 py-3 rounded text-red-700">
                    {message}
                </div>
            )}
        </div>
    );
}

export default Project;
