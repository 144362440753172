import { Link } from 'react-router-dom';

function CreateIndex() {
    const lightCreateBtnStyles =
        'self-stretch w-full flex flex-col gap-3 bg-white text-left border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 rounded-lg px-5 py-2.5';
    const darkCreateBtnStyles =
        'dark:bg-gray-700/20 dark:text-primary-dark-text dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700';
    const createBtnStyles = `${lightCreateBtnStyles} ${darkCreateBtnStyles}`;

    return (
        <div className="p-8 space-y-4 m-auto border-solid border-2 border-gray-300 dark:border-primary-dark-border rounded-lg text-sm shadow-md">
            <Link className={createBtnStyles} to="/create/survey">
                <span className="font-medium text-lg">Survey</span>
                Create a survey with our no-code builder
            </Link>
            <Link className={createBtnStyles} to="/create/project">
                <span className="font-medium text-lg">Project</span>
                Add a survey to a project to begin collecting responses
            </Link>
        </div>
    );
}

export default CreateIndex;
