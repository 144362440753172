import { json, redirect } from 'react-router-dom';

export async function login(data: FormData) {
    const response = await fetch('/api/login', {
        method: 'POST',
        body: data,
    });
    if (response.ok) {
        const data = await response.json();
        return {
            success: data.success,
            token_used: data.token_used,
            message: 'Login successful!',
        };
    } else if (response.status === 504) {
        return {
            success: false,
            token_used: false,
            message: 'No response from server.',
        };
    } else {
        const errorData = await response.json();
        return {
            success: false,
            token_used: false,
            message: errorData.detail,
        };
    }
}

export async function loginAsAdmin(data: FormData) {
    const response = await fetch('/api/admin/login', {
        method: 'POST',
        body: data,
    });
    if (response.ok) {
        return 'Admin login successful!';
    } else if (response.status === 504) {
        return 'No response from server.';
    } else {
        const errorData = await response.json();
        return errorData.detail;
    }
}

export async function logout() {
    const response = await fetch('/api/logout', {
        method: 'GET',
    });
    if (response.ok) {
        return 'Logout successful!';
    } else if (response.status === 504) {
        return 'No response from server.';
    } else {
        const errorData = await response.json();
        return errorData.detail;
    }
}

export async function logoutAsAdmin() {
    const response = await fetch('/api/admin/logout', {
        method: 'GET',
    });
    if (response.ok) {
        return 'Admin logout successful!';
    } else if (response.status === 504) {
        return 'No response from server.';
    } else {
        const errorData = await response.json();
        return errorData.detail;
    }
}

export async function betaAuthCheck() {
    const response = await fetch('/api/beta-auth', {
        method: 'GET',
    });
    if (response.ok) {
        return response;
    } else if (response.status === 401) {
        return redirect('/login');
    } else if (response.status === 504) {
        throw json(
            { message: 'No response from server.' },
            { status: response.status }
        );
    } else {
        const errorData = await response.json();
        throw json({ message: errorData.detail }, { status: response.status });
    }
}

export async function softAuthCheck() {
    const response = await fetch('/api/soft-auth', {
        method: 'GET',
    });

    if (response.ok) {
        return response;
    } else if (response.status === 504) {
        return { message: 'No response from server.' };
    } else {
        const errorData = await response.json();
        return { message: errorData.detail };
    }
}

export async function adminAuthCheck() {
    const response = await fetch('/api/admin-auth', {
        method: 'GET',
    });

    if (response.ok) {
        return response;
    } else if (response.status === 401) {
        return redirect('/admin/login');
    } else if (response.status === 504) {
        return { message: 'No response from server.' };
    } else {
        const errorData = await response.json();
        return { message: errorData.detail };
    }
}
