import * as Tabs from '@radix-ui/react-tabs';
import Question from './Question';
import SurveyFlow from './SurveyFlow';
import { Survey } from '../../../../../types';

function Survey({ survey }: { survey: Survey }) {
    const questions = survey.data.questions;

    return (
        <Tabs.Root defaultValue="questions">
            <Tabs.List
                className="flex gap-x-2 mb-4 border-b border-gray-200 dark:border-gray-700"
                aria-label="Switch tabs"
            >
                <Tabs.Trigger
                    className="p-4 rounded-t-lg border-b-2 text-sm font-medium text-center data-[state=active]:text-blue-600 data-[state=active]:border-blue-600 data-[state=inactive]:border-transparent dark:data-[state=active]:text-primary-dark-text-accent dark:data-[state=active]:border-primary-dark-text-accent"
                    value="questions"
                >
                    Questions
                </Tabs.Trigger>
                <Tabs.Trigger
                    className="p-4 rounded-t-lg border-b-2 text-sm font-medium text-center data-[state=active]:text-blue-600 data-[state=active]:border-blue-600 data-[state=inactive]:border-transparent dark:data-[state=active]:text-primary-dark-text-accent dark:data-[state=active]:border-primary-dark-text-accent"
                    value="flow"
                >
                    Flow
                </Tabs.Trigger>
            </Tabs.List>
            <Tabs.Content value="questions">
                <div className="space-y-4 py-3">
                    {questions.map((question, index) => (
                        <Question
                            key={index}
                            questionOptions={question.question_options}
                            questionText={question.question_text}
                            questionType={question.question_type}
                        />
                    ))}
                </div>
            </Tabs.Content>
            <Tabs.Content value="flow">
                <SurveyFlow
                    questions={questions}
                    rule_collection={survey.data.rule_collection}
                />
            </Tabs.Content>
        </Tabs.Root>
    );
}

export default Survey;
