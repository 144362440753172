export async function action({ request }) {
    const formData = await request.formData();

    const response = await fetch('/api/reset-password', {
        method: 'POST',
        body: formData,
    });

    if (response.ok) {
        return 'Password successfully reset! You can now log in.';
    } else if (response.status === 504) {
        return 'No response from server.';
    } else {
        const errorData = await response.json();
        return errorData.detail;
    }
}
