import KeyValueTable from './KeyValueTable';

function Model({ params }: { params: object }) {
    const data = Object.entries(params).map(([param, value], index) => {
        return { id: index, key: param, value: value };
    });

    return (
        <KeyValueTable
            title="Model parameters"
            keyColName="Parameter"
            valueColName="Value"
            data={data}
        />
    );
}

export default Model;
