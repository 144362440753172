import { QuestionException, TaskHistory } from './types';
import { useCopyToClipboard } from '../../../../../../../../hooks/useCopyToClipboard';

function CopyCodeBtn({ code }: { code: string }) {
    const [isCopied, copy] = useCopyToClipboard();

    function handleCopy(text: string) {
        copy(text).catch((error) => {
            console.error('Failed to copy!', error);
        });
    }

    return (
        <button
            type="button"
            onClick={() => handleCopy(code)}
            className="w-20 mr-4 py-2.5 bg-blue-600 hover:bg-blue-700 rounded-md text-white font-semibold"
        >
            {isCopied === false ? 'Copy' : 'Copied!'}
        </button>
    );
}

function ExceptionDetails({ exception }: { exception: QuestionException }) {
    const tableStyles =
        'border-collapse border border-slate-700 dark:border-slate-400';
    const tableCellStyles =
        'table-fixed p-2 border border-slate-700 dark:border-slate-400 text-wrap';

    return (
        <div className="space-y-4 px-3 py-3 bg-red-50 border-l-4 border-red-500 dark:bg-red-950 dark:bg-opacity-30 dark:border-transparent">
            <p className="text-red-500 dark:text-primary-dark-text font-bold">
                Exception: {exception.exception_message}
            </p>
            <table className={tableStyles}>
                <thead>
                    <tr>
                        <th className={`w-1/2 ${tableCellStyles}`}>Key</th>
                        <th className={`w-1/2 ${tableCellStyles}`}>Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className={tableCellStyles}>
                            Interview ID (index in results)
                        </td>
                        <td className={tableCellStyles}>
                            {exception.interview_id}
                        </td>
                    </tr>
                    <tr>
                        <td className={tableCellStyles}>
                            Question name (question_name)
                        </td>
                        <td className={tableCellStyles}>
                            {exception.question_name}
                        </td>
                    </tr>

                    <tr>
                        <td className={tableCellStyles}>
                            Question type (question_type)
                        </td>
                        <td className={tableCellStyles}>
                            {exception.question_type}
                        </td>
                    </tr>

                    <tr>
                        <td className={tableCellStyles}>
                            Human-readable question
                        </td>
                        <td className={tableCellStyles}>
                            This section is in progress!
                        </td>
                    </tr>
                    <tr>
                        <td className={tableCellStyles}>Scenario</td>
                        <td className={tableCellStyles}>
                            <pre className="text-sm text-wrap">
                                <code>{exception.scenario_repr}</code>
                            </pre>
                        </td>
                    </tr>
                    <tr>
                        <td className={tableCellStyles}>Agent</td>
                        <td className={tableCellStyles}>
                            <pre className="text-sm text-wrap">
                                <code>{exception.agent_repr}</code>
                            </pre>
                        </td>
                    </tr>
                    <tr>
                        <td className={tableCellStyles}>Model name</td>
                        <td className={tableCellStyles}>
                            {exception.model_name}
                        </td>
                    </tr>
                    <tr>
                        <td className={tableCellStyles}>Inference service</td>
                        <td className={tableCellStyles}>
                            {exception.inference_service}
                        </td>
                    </tr>
                    <tr>
                        <td className={tableCellStyles}>Model parameters</td>
                        <td className={tableCellStyles}>
                            <pre className="text-sm text-wrap">
                                <code>{exception.model_repr}</code>
                            </pre>
                        </td>
                    </tr>
                    <tr>
                        <td className={tableCellStyles}>User Prompt</td>
                        <td className={tableCellStyles}>
                            <pre className="text-sm text-wrap">
                                <code>{exception.user_prompt}</code>
                            </pre>
                        </td>
                    </tr>
                    <tr>
                        <td className={tableCellStyles}>System Prompt</td>
                        <td className={tableCellStyles}>
                            <pre className="text-sm text-wrap">
                                <code>{exception.system_prompt}</code>
                            </pre>
                        </td>
                    </tr>
                    <tr>
                        <td className={tableCellStyles}>Raw model response</td>
                        <td className={tableCellStyles}>
                            <pre className="text-sm text-wrap">
                                <code>{exception.raw_model_response}</code>
                            </pre>
                        </td>
                    </tr>
                    <tr>
                        <td className={tableCellStyles}>
                            Generated token string (at {exception.key_sequence})
                            in raw response
                        </td>
                        <td className={tableCellStyles}>
                            <pre className="text-sm text-wrap">
                                <code>{exception.generated_token_string}</code>
                            </pre>
                        </td>
                    </tr>
                    <tr>
                        <td className={tableCellStyles}>
                            Code to (likely) reproduce the error
                        </td>
                        <td className={tableCellStyles}>
                            <textarea
                                className="w-full text-sm"
                                rows={10}
                                defaultValue={exception.code_to_reproduce}
                            />
                            <CopyCodeBtn code={exception.code_to_reproduce} />
                        </td>
                    </tr>
                </tbody>
            </table>
            <div>Time: {exception.time}</div>
            <div>
                Traceback:
                <br />
                <br />
                <text>
                    <pre className="text-sm text-wrap italic">
                        <code>{exception.traceback}</code>
                    </pre>
                </text>
            </div>
        </div>
    );
}

function Interviews({ taskHistory }: { taskHistory: TaskHistory }) {
    return (
        <div className="space-y-6">
            {taskHistory.interviews.map((interview, index) => (
                <div className="space-y-4" key={index}>
                    <div className="px-3 py-3 bg-sky-100 border-l-4 border-sky-300 dark:bg-sky-800 dark:border-transparent">
                        <h1 className="text-2xl font-bold">
                            Interview: {interview.index}
                        </h1>
                    </div>
                    <p>Model: {interview.model}</p>
                    <h2 className="text-xl font-bold">Failing questions</h2>
                    {interview.interview_exceptions.map(
                        (question, questionIndex) => (
                            <div className="space-y-4" key={questionIndex}>
                                <div className="px-3 py-3 bg-yellow-100 border-l-4 border-yellow-300 dark:bg-yellow-800 dark:border-transparent text-lg">
                                    <p>
                                        question_name: {question.question_name}
                                    </p>
                                </div>
                                <p className="font-bold text-lg">
                                    Exception Details
                                </p>
                                {question.question_exceptions.map(
                                    (exception, exceptionIndex) => (
                                        <ExceptionDetails
                                            key={exceptionIndex}
                                            exception={exception}
                                        />
                                    )
                                )}
                            </div>
                        )
                    )}
                </div>
            ))}
        </div>
    );
}

export default Interviews;
