import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ArrowUp, ArrowDown, Search } from 'react-feather';
import ObjectTableRow from './ObjectTableRow';
import Pagination from '../Pagination';
import { Object } from '../../../types';

interface TableProps {
    currentPage: number;
    isAdmin?: boolean;
    objects: Object[];
    pageType: 'home' | 'explore';
    totalPages: number;
}

function ObjectTable({
    currentPage,
    objects,
    pageType,
    totalPages,
    isAdmin = false,
}: TableProps) {
    const [selectedRowId, setSelectedRowId] = useState('');

    function selectRow(rowId: string) {
        setSelectedRowId(rowId);
    }

    function getSortIcon(colName: string) {
        const searchParams = new URLSearchParams(window.location.search);
        const sortColName = searchParams.get('sort_col');
        const sortAscending = searchParams.get('sort_ascending');

        if (colName === sortColName && sortAscending === 'True') {
            return <ArrowUp className="inline w-4 h-4" strokeWidth="2" />;
        } else if (colName === sortColName && sortAscending === 'False') {
            return <ArrowDown className="inline w-4 h-4" strokeWidth="2" />;
        } else {
            return (
                <ArrowUp
                    className="inline w-4 h-4 opacity-0 group-hover:opacity-100"
                    strokeWidth="2"
                />
            );
        }
    }

    function getSortURL(colName: string) {
        const searchParams = new URLSearchParams(window.location.search);
        const sortColName = searchParams.get('sort_col');
        const sortAscending = searchParams.get('sort_ascending');

        searchParams.set('sort_col', colName);
        if (colName === sortColName && sortAscending === 'True') {
            searchParams.set('sort_ascending', 'False');
        } else {
            searchParams.set('sort_ascending', 'True');
        }

        return `?${searchParams.toString()}`;
    }

    return (
        <>
            <div className="mb-8 overflow-x-auto rounded-lg">
                <div className="relative min-h-[60vh]">
                    <table className="min-w-full text-sm relative">
                        <thead className="bg-white dark:bg-primary-dark-bg sticky border-b-2 dark:border-gray-100/20 top-0">
                            <tr>
                                {/* Add star column */}
                                <th className="py-2 px-4 text-left w-8 font-semibold"></th>
                                <th className="text-left w-8 font-semibold">
                                    <Link
                                        className="flex items-center gap-x-1 w-full h-full py-2 px-3 hover:bg-gray-100 dark:hover:bg-gray-50/10 group"
                                        to={getSortURL('object_type')}
                                    >
                                        Type {getSortIcon('object_type')}
                                        <i className="fa-solid fa-arrow-up ml-1 opacity-0 group-hover:opacity-100"></i>
                                    </Link>
                                </th>
                                <th className="text-left w-72 font-semibold">
                                    <Link
                                        className="flex items-center gap-x-1 w-full h-full py-2 px-3 hover:bg-gray-100 dark:hover:bg-gray-50/10 group"
                                        to={getSortURL('description')}
                                    >
                                        Description {getSortIcon('description')}
                                        <i className="fa-solid fa-arrow-up ml-1 opacity-0 group-hover:opacity-100"></i>
                                    </Link>
                                </th>
                                <th className="text-left w-40 font-semibold">
                                    <Link
                                        className="flex items-center gap-x-1 w-full h-full py-2 px-3 hover:bg-gray-100 dark:hover:bg-gray-50/10 group"
                                        to={getSortURL('created_ts')}
                                    >
                                        Created {getSortIcon('created_ts')}
                                        <i className="fa-solid fa-arrow-up ml-1 opacity-0 group-hover:opacity-100"></i>
                                    </Link>
                                </th>
                                <th className="text-left w-40 font-semibold">
                                    <Link
                                        className="flex items-center gap-x-1 w-full h-full py-2 px-3 hover:bg-gray-100 dark:hover:bg-gray-50/10 group"
                                        to={getSortURL('last_updated_ts')}
                                    >
                                        Updated {getSortIcon('last_updated_ts')}
                                        <i className="fa-solid fa-arrow-up ml-1 opacity-0 group-hover:opacity-100"></i>
                                    </Link>
                                </th>
                                <th className="text-left w-32 font-semibold">
                                    <Link
                                        className="flex items-center gap-x-1 w-full h-full py-2 px-3 hover:bg-gray-100 dark:hover:bg-gray-50/10 group"
                                        to={getSortURL('owner')}
                                    >
                                        Owner {getSortIcon('owner')}
                                        <i className="fa-solid fa-arrow-up ml-1 opacity-0 group-hover:opacity-100"></i>
                                    </Link>
                                </th>
                                {/* Visibility on the Explore page is always public - no need for column */}
                                {pageType === 'home' && (
                                    <th className="text-left w-32 font-semibold">
                                        <Link
                                            className="flex items-center gap-x-1 w-full h-full py-2 px-3 hover:bg-gray-100 dark:hover:bg-gray-50/10 group"
                                            to={getSortURL('visibility')}
                                        >
                                            Vis. {getSortIcon('visibility')}
                                            <i className="fa-solid fa-arrow-up ml-1 opacity-0 group-hover:opacity-100"></i>
                                        </Link>
                                    </th>
                                )}
                                <th className="py-2 px-4 text-left w-16"></th>
                            </tr>
                        </thead>
                        <tbody className="align-top">
                            {objects.map((object) => (
                                <ObjectTableRow
                                    key={object.id}
                                    isAdmin={isAdmin}
                                    pageType={pageType}
                                    object={object}
                                    isSelected={selectedRowId === object.id}
                                    selectRow={selectRow}
                                />
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <Pagination
                style="color"
                currentPage={currentPage}
                totalPages={totalPages}
            />
        </>
    );
}

export default ObjectTable;
