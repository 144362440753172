import { useMatches } from 'react-router-dom';

export default function useAuth() {
    const matches = useMatches();

    const authenticatorRoute = matches.find(
        (match) =>
            match.data !== undefined &&
            match.data.hasOwnProperty('is_authenticated')
    );
    const betaAuthenticatorRoute = matches.find(
        (match) =>
            match.data !== undefined &&
            match.data.hasOwnProperty('is_beta_user')
    );

    let isLoggedIn: boolean;
    if (authenticatorRoute) {
        isLoggedIn = authenticatorRoute.data['is_authenticated'];
    } else {
        isLoggedIn = false;
    }

    let isBetaUser: boolean;
    if (betaAuthenticatorRoute) {
        isBetaUser = authenticatorRoute.data['is_beta_user'];
    } else {
        isBetaUser = false;
    }

    return { isBetaUser, isLoggedIn };
}
