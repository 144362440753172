import { Link } from 'react-router-dom';
import CodeBlock from '../../CodeBlock';
import InlineCode from '../../InlineCode';

function CoopRemoteInference() {
    const linkStyles =
        'text-blue-500 hover:text-blue-600 hover:underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent';

    const remoteInferenceToggleImageURL = new URL(
        '../../../../../public/assets/getting-started/remote-inference-toggle.png',
        import.meta.url
    ).pathname;
    const remoteInferencePageImageURL = new URL(
        '../../../../../public/assets/getting-started/remote-inference-page.png',
        import.meta.url
    ).pathname;
    const creditsPageImageURL = new URL(
        '../../../../../public/assets/getting-started/credits-page.png',
        import.meta.url
    ).pathname;
    const remoteInferenceResultsImageURL = new URL(
        '../../../../../public/assets/getting-started/remote-inference-results.png',
        import.meta.url
    ).pathname;

    return (
        <>
            <div className="space-y-2">
                <h1 className="font-bold text-4xl pb-2">
                    Coop: Remote inference
                </h1>
                <p className="text-lg">
                    With remote inference, you can run LLM jobs on our servers
                    instead of your local machine.
                </p>
                <br />
                <h2 className="font-bold text-2xl pb-2">
                    1. Activate remote inference
                </h2>
                <p>
                    Start off by storing your Expected Parrot API key (see the
                    tutorial on the{' '}
                    <Link
                        to="/getting-started/coop-how-it-works"
                        className={linkStyles}
                    >
                        How it works
                    </Link>{' '}
                    page.)
                </p>
                <p>
                    Then log in to Coop and go to the{' '}
                    <Link to="/home/api" className={linkStyles}>
                        API settings page
                    </Link>
                    , and turn on the remote inference toggle:
                </p>
                <br />
                <img
                    src={remoteInferenceToggleImageURL}
                    alt="Screenshot of the Coop API page. The remote inference toggle has been turned on"
                    className="max-w-xl mx-auto"
                />
                <br />
                <h2 className="font-bold text-2xl pb-2">
                    2. Run a job with EDSL
                </h2>
                <p>
                    With remote inference activated, calling the{' '}
                    <InlineCode>run()</InlineCode> method will automatically
                    send your job to the Expected Parrot server. You can
                    optionally pass a{' '}
                    <InlineCode>remote_inference_description</InlineCode> string
                    to identify the job on Coop.
                </p>
                <br />
                <CodeBlock>
                    from edsl import Survey
                    <br />
                    <br />
                    survey = Survey.example() <br />
                    <br />
                    results = survey.run(remote_inference_description="Example
                    survey", verbose=True)
                </CodeBlock>
                <br />
                <h2 className="font-bold text-2xl pb-2">3. Job status</h2>
                <p>
                    While it is running, the job will appear on your{' '}
                    <Link to="/home/remote-inference" className={linkStyles}>
                        Coop remote inference page
                    </Link>{' '}
                    with a status of "Queued":
                </p>
                <br />
                <img
                    src={remoteInferencePageImageURL}
                    alt="Screenshot of the Coop remote inference page. There is one queued job"
                    className="max-w-xl mx-auto"
                />
                <br />
                <p>There are 6 status indicators for a job:</p>
                <p>
                    <ol className="list-decimal list-inside">
                        <li>
                            <span className="font-bold">Queued:</span> Your job
                            has been added to the queue.
                        </li>
                        <li>
                            <span className="font-bold">Running:</span> Your job
                            has started running.
                        </li>
                        <li>
                            <span className="font-bold">Completed:</span> Your
                            job has finished running successfully.
                        </li>
                        <li>
                            <span className="font-bold">Failed:</span> Your job
                            threw an error.
                        </li>
                        <li>
                            <span className="font-bold">Cancelling:</span> You
                            have sent a cancellation request by pressing the
                            Cancel button.
                        </li>
                        <li>
                            <span className="font-bold">Cancelled:</span> Your
                            cancellation request has been processed. Your job
                            will no longer run.
                        </li>
                    </ol>
                </p>
                <p>
                    Once your job has completed, its status will change to
                    "Completed", and you can click on{' '}
                    <span className="font-bold">View</span> to view the results.
                </p>
                <p>
                    Your results will also show up on the{' '}
                    <Link to="/content" className={linkStyles}>
                        Coop content page
                    </Link>{' '}
                    with a check icon that identifies them as remote inference
                    results:
                </p>
                <br />
                <img
                    src={remoteInferenceResultsImageURL}
                    alt="Screenshot of the Coop content page. The user has remote inference results, indicated by a check mark"
                    className="mx-auto"
                />
                <br />
                <h2 className="font-bold text-2xl pb-2">4. Credits</h2>
                <p>
                    Using remote inference consumes credits. New users are given
                    100 credits to start off.
                </p>
                <p>
                    You can view your balance and purchase more credits on the{' '}
                    <Link to="/home/purchases" className={linkStyles}>
                        Coop credits page
                    </Link>
                    :
                </p>
                <br />
                <img
                    src={creditsPageImageURL}
                    alt="Screenshot of the Coop credits page. The user has a balance of 100 credits. There is a link to purchase credits with Stripe"
                    className="max-w-xl mx-auto"
                />
                <br />
            </div>
        </>
    );
}

export default CoopRemoteInference;
