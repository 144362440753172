import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import Message from '../base/Message';

function GlobalErrorPage() {
    const error = useRouteError();

    if (isRouteErrorResponse(error)) {
        const title = `HTTP ${error.status}`;
        const message = error.data.message;
        return <Message title={title} mainMessage={message} />;
    }

    return (
        <Message
            title="Expected Parrot"
            mainMessage="This page either does not exist, or we are working on it 👩🏻‍💻👨‍💻🛠️"
        />
    );
}

export default GlobalErrorPage;
