export async function action({ request }) {
    const formData = await request.formData();
    let intent = formData.get('intent');

    let response: Response;

    if (intent === 'update_credits') {
        response = await fetch('/api/admin/update-credits', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: formData.get('email'),
                increment: formData.get('increment'),
                gift_note: formData.get('gift_note'),
            }),
        });
    } else if (intent === 'delete_user') {
        response = await fetch('/api/admin/users/delete', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: formData.get('email'),
            }),
        });
    } else if (intent === 'delete_unverified_users') {
        alert('Unverified user check has started.');
        response = await fetch('/api/admin/delete-unverified-users', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }
    if (response.ok && intent === 'delete_unverified_users') {
        const successData = await response.json();
        const unverifiedUserCount = successData.unverified_users;
        const successMessage = `Number of unverified users deleted: ${unverifiedUserCount}`;
        alert(successMessage);
        return { success: true, message: successData.message };
    } else if (response.ok) {
        const successData = await response.json();
        return { success: true, message: successData.message };
    } else if (!response.ok && intent === 'delete_unverified_users') {
        const errorMessage = 'An error occurred checking for unverified users.';
        alert('An error occurred checking for unverified users.');
        return { success: false, message: errorMessage };
    } else if (response.status === 422) {
        return { success: false, message: 'Pydantic error.' };
    } else if (response.status === 504) {
        return { success: false, message: 'No response from server.' };
    } else {
        const errorData = await response.json();
        return { success: false, message: errorData.detail };
    }
}
