interface ContentContainerProps {
    additionalClasses?: string;
    children: React.ReactNode;
}

function ContentContainer({
    additionalClasses = '',
    children,
}: ContentContainerProps) {
    return (
        <div className="h-full w-full flex flex-col grow items-stretch justify-start">
            <div
                className={`h-full flex flex-col overflow-y-auto px-5 pt-5 pb-16 sm:px-16 space-y-8 ${additionalClasses}`}
            >
                {children}
            </div>
        </div>
    );
}

export default ContentContainer;
