import { useEffect } from 'react';
import { useFetcher } from 'react-router-dom';
import { Eye } from 'react-feather';
import { JobState, ScenarioPreview } from './types';

function ScenarioPreview({
    scenarioPreview,
}: {
    scenarioPreview: ScenarioPreview | null;
}) {
    if (scenarioPreview === null) {
        return;
    }

    return (
        <div>
            <h4 className="text-lg font-semibold mb-2">Scenario preview</h4>
            <p className="mb-2">Rows: {scenarioPreview.num_rows}</p>
            <p className="mb-2">
                Keys: {scenarioPreview.keys.length} -{' '}
                {JSON.stringify(scenarioPreview.keys)}
            </p>
            <p className="mt-2 text-sm text-gray-500">(Showing first 5 rows)</p>
            <div className="overflow-x-auto">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            {Object.keys(
                                scenarioPreview.first_five_rows[0] || {}
                            ).map((header) => (
                                <th
                                    key={header}
                                    scope="col"
                                    className="px-6 py-3"
                                >
                                    {header}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {scenarioPreview.first_five_rows
                            .slice(0, 5)
                            .map((record, index) => (
                                <tr
                                    key={index}
                                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                                >
                                    {Object.values(record).map(
                                        (value, cellIndex) => (
                                            <td
                                                key={cellIndex}
                                                className="px-6 py-4"
                                            >
                                                {value.toString()}
                                            </td>
                                        )
                                    )}
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

function UploadScenariosForm({
    jobState,
    handleFileChange,
    scenarioPreview,
    handleUpdateScenarioPreview,
}: {
    jobState: JobState;
    handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    scenarioPreview: ScenarioPreview | null;
    handleUpdateScenarioPreview: (data: object) => void;
}) {
    const fetcher = useFetcher();

    useEffect(() => {
        if (
            fetcher.state === 'idle' &&
            fetcher.data &&
            typeof fetcher.data !== 'string'
        ) {
            handleUpdateScenarioPreview(fetcher.data);
        }
    }, [fetcher.state, fetcher.data, handleUpdateScenarioPreview]);

    function handleSubmit() {
        const formData = new FormData();
        formData.append('file', jobState.scenarioFile);
        formData.append('intent', 'upload_scenarios');
        fetcher.submit(formData, {
            method: 'post',
            encType: 'multipart/form-data',
        });
    }

    return (
        <div className="space-y-4">
            <div>
                <label
                    htmlFor="scenario-file"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                    Select CSV file
                </label>
                <input
                    id="scenario-file"
                    name="file"
                    type="file"
                    accept=".csv"
                    onChange={handleFileChange}
                    className="block w-full text-sm text-gray-900 border border-gray-300 cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                />
            </div>
            <div className="flex items-center space-x-2">
                <button
                    type="submit"
                    onClick={handleSubmit}
                    className="flex items-center px-4 py-2.5 hover:bg-gray-300/20 hover:transition-colors border border-gray-300 dark:border-2 dark:border-gray-500 rounded-md font-medium text-sm"
                >
                    <Eye className="w-4 h-4 mr-2" />
                    Preview scenarios
                </button>
            </div>
            <ScenarioPreview scenarioPreview={scenarioPreview} />
        </div>
    );
}

export default UploadScenariosForm;
