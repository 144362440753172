import { Outlet, useOutletContext } from 'react-router-dom';
import WebAppContainer from '../base/WebAppContainer';

function Content() {
    const isDarkMode: boolean = useOutletContext();

    return (
        <WebAppContainer>
            <Outlet context={isDarkMode} />
        </WebAppContainer>
    );
}

export default Content;
