import * as Switch from '@radix-ui/react-switch';

function Toggle({ id, ...delegated }) {
    const rootColor =
        'bg-gray-300 data-[state=checked]:bg-blue-500 dark:bg-primary-dark-bg dark:data-[state=checked]:bg-primary-dark-bg dark:ring-1 dark:ring-primary-dark-text/60 dark:data-[state=checked]:ring-primary-dark-text-accent';
    const thumbColor =
        'bg-white dark:bg-primary-dark-text/50 dark:data-[state=checked]:bg-primary-dark-text-accent';

    return (
        <Switch.Root
            className={`${rootColor} w-[48px] h-[25px] rounded-full relative`}
            id={id}
            {...delegated}
        >
            <Switch.Thumb
                className={`${thumbColor} block w-[21px] h-[21px] rounded-full transition-transform duration-100 translate-x-[2px] will-change-transform data-[state=checked]:translate-x-[25px]`}
            />
        </Switch.Root>
    );
}

export default Toggle;
