import { useLoaderData } from 'react-router-dom';
import ObjectTable, { ObjectTableWrapper } from '../../../base/ObjectTable';
import { ObjectTableData } from '../../../../types';

function Objects() {
    const data = useLoaderData() as ObjectTableData;

    function getTableOrMessage() {
        if (data.results_status === 'no content uploaded') {
            return (
                <div className="text-2xl text-center space-y-4">
                    <p>No objects found 😔</p>
                    <p>Please check back later!</p>
                </div>
            );
        } else if (data.results_status === 'no results found') {
            return <p className="text-2xl text-center">No results found 😔</p>;
        } else {
            return (
                <ObjectTable
                    isAdmin={true}
                    pageType="home"
                    objects={data.objects}
                    currentPage={data.current_page}
                    totalPages={data.total_pages}
                />
            );
        }
    }

    return (
        <div className="flex flex-col">
            <ObjectTableWrapper
                pageType="home"
                owners={data.owners}
                showTabs={false}
            >
                {getTableOrMessage()}
            </ObjectTableWrapper>
        </div>
    );
}

export default Objects;
