import { TaskHistory } from './types';

function Overview({ taskHistory }: { taskHistory: TaskHistory }) {
    const linkStyles = 'text-blue-500 hover:underline';

    return (
        <div className="space-y-4">
            <h1 className="text-2xl font-bold">Overview</h1>
            <p>
                There were {taskHistory.interviews.length} total interview(s).
                An 'interview' is the result of one survey, taken by one agent,
                with one model, with one scenario.
            </p>
            <p>
                The number of interviews with any exceptions was{' '}
                {taskHistory.num_exceptions}.
            </p>
            <p>
                For advice on dealing with exceptions on Expected Parrot, see{' '}
                <a
                    className={linkStyles}
                    href="https://docs.expectedparrot.com/en/latest/exceptions.html"
                >
                    here
                </a>
                .
            </p>
        </div>
    );
}

export default Overview;
