import { Link, useLoaderData } from 'react-router-dom';

interface ModelPrice {
    service: string;
    model: string;
    mode: string;
    input_price_per_1M_tokens: number | null;
    output_price_per_1M_tokens: number | null;
}

interface CoopPricingData {
    is_authenticated: boolean;
    is_beta_user: boolean;
    model_prices: ModelPrice[];
}

function formatPrice(price: number | null) {
    if (price === null) {
        return;
    } else if (price === 0) {
        return `< $0.01`;
    } else {
        return `$${price.toFixed(2)}`;
    }
}

function CoopPricing() {
    const data = useLoaderData() as CoopPricingData;

    const linkStyles =
        'text-blue-500 hover:text-blue-600 hover:underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent';

    return (
        <>
            <div className="space-y-2">
                <h1 className="font-bold text-4xl pb-2">Pricing</h1>
                <p className="text-lg">
                    The following model token prices from service providers are
                    used to calculate credits required for running surveys
                    remotely on the Expected Parrot server.{' '}
                    <Link
                        to="https://docs.expectedparrot.com/en/latest/credits.html"
                        target="_blank"
                        className={linkStyles}
                    >
                        Learn more about purchasing and using credits.
                    </Link>
                </p>
            </div>
            <div className="mb-8 overflow-x-auto rounded-lg">
                <div className="relative">
                    <table className="min-w-full text-sm relative">
                        <thead className="bg-white dark:bg-primary-dark-bg sticky border-b-2 dark:border-gray-100/20 top-0">
                            <tr>
                                <th className="text-left w-40 font-semibold">
                                    <div className="flex items-center gap-x-1 w-full h-full py-2 px-3">
                                        Service
                                    </div>
                                </th>
                                <th className="text-left w-40 font-semibold">
                                    <div className="flex items-center gap-x-1 w-full h-full py-2 px-3">
                                        Model
                                    </div>
                                </th>
                                <th className="text-left w-32 font-semibold">
                                    <div className="flex items-center gap-x-1 w-full h-full py-2 px-3">
                                        Mode
                                    </div>
                                </th>
                                <th className="text-left w-40 font-semibold">
                                    <div className="flex items-center gap-x-1 w-full h-full py-2 px-3">
                                        Input (Price per 1M tokens)
                                    </div>
                                </th>
                                <th className="text-left w-40 font-semibold">
                                    <div className="flex items-center gap-x-1 w-full h-full py-2 px-3">
                                        Output (Price per 1M tokens)
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="align-top">
                            {data.model_prices.map((model, index) => (
                                <tr
                                    key={index}
                                    className={
                                        'border-b-2 dark:border-gray-100/20 dark:text-secondary-dark-text hover:bg-gray-100 dark:hover:bg-gray-50/5'
                                    }
                                >
                                    <td className="py-3 px-3 w-40">
                                        {model.service}
                                    </td>
                                    <td className="py-3 px-3 w-40">
                                        {model.model}
                                    </td>
                                    <td className="py-3 px-3 w-32">
                                        {model.mode}
                                    </td>
                                    <td className="py-3 px-3 w-40">
                                        {formatPrice(
                                            model.input_price_per_1M_tokens
                                        )}
                                    </td>
                                    <td className="py-3 px-3 w-40">
                                        {formatPrice(
                                            model.output_price_per_1M_tokens
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default CoopPricing;
