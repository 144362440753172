import { redirect } from 'react-router-dom';

export async function action({ request }) {
    const data = await request.json();
    const intent = data.intent;
    const questions = JSON.parse(data.questions);

    let response: Response;

    if (intent === 'get_code') {
        response = await fetch(`/api/survey-code`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ questions }),
        });
    } else if (intent === 'create_object') {
        response = await fetch(`/api/survey-object`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ questions }),
        });
    } else if (intent === 'launch_project') {
        const project_name = data.project_name;
        response = await fetch(`/api/projects/create-from-survey-builder`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ questions, project_name }),
        });
    }

    if (response.ok && intent === 'get_code') {
        const successData = await response.json();
        return { success: true, intent: intent, message: successData.code };
    } else if (response.ok && intent === 'create_object') {
        const successData = await response.json();
        const objectId = successData.uuid;
        return redirect(`/content/${objectId}`);
    } else if (response.ok && intent === 'launch_project') {
        const successData = await response.json();
        const projectId = successData.uuid;
        return redirect(`/home/projects/${projectId}`);
    } else if (response.status === 401) {
        return redirect('/login');
    } else if (response.status === 422) {
        const errorData = await response.json();
        return { success: false, intent: intent, message: 'Pydantic error.' };
    } else if (response.status === 504) {
        return {
            success: false,
            intent: intent,
            message: 'No response from server.',
        };
    } else {
        const errorData = await response.json();
        return { success: false, intent: intent, message: errorData.detail };
    }
}
