import { useLoaderData, useSubmit, useActionData } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import ContentContainer from '../../../base/ContentContainer';
import { Question as QuestionType } from './types';
import { Question } from './Questions';

interface SurveyData {
    survey: {
        questions: QuestionType[];
    };
}

const createSurveySchema = (questions: QuestionType[]) => {
    const schemaFields: Record<string, z.ZodTypeAny> = {};

    questions.forEach((question) => {
        switch (question.question_type) {
            case 'budget':
                schemaFields[question.question_name] = z
                    .record(z.string())
                    .refine(
                        (budgetObject) => {
                            return Object.values(budgetObject).every(
                                (value) => !isNaN(Number(value))
                            );
                        },
                        {
                            message: 'All budget values must be valid numbers',
                        }
                    )
                    .refine(
                        (budgetObject) => {
                            return Object.values(budgetObject).every(
                                (value) => Number(value) >= 0
                            );
                        },
                        {
                            message:
                                'All budget values must be non-negative numbers',
                        }
                    )
                    .refine(
                        (budgetObject) => {
                            const sum = Object.values(budgetObject).reduce(
                                (acc, value) => acc + Number(value),
                                0
                            );
                            return (
                                Math.abs(sum - question.budget_sum) < 0.00001
                            ); // Allow for small floating-point errors
                        },
                        {
                            message: `The total budget must equal ${question.budget_sum}`,
                        }
                    )
                    .transform((budgetObject) =>
                        Object.entries(budgetObject).map(([key, value]) => ({
                            [key]: Number(value),
                        }))
                    );
                break;
            case 'checkbox':
                schemaFields[question.question_name] = z
                    .array(z.string())
                    .min(
                        question.min_selections === null
                            ? 0
                            : question.min_selections,
                        `Please select at least ${question.min_selections} option(s)`
                    )
                    .max(
                        question.max_selections === null
                            ? question.question_options.length
                            : question.max_selections,
                        `Please select at most ${question.min_selections} option(s)`
                    );
                break;
            case 'free_text':
                schemaFields[question.question_name] = z
                    .string({
                        required_error: 'This field is required',
                    })
                    .min(1, 'This field is required');
                break;
            case 'likert_five':
                schemaFields[question.question_name] = z
                    .string()
                    .min(1, 'Please select an option');
                break;
            case 'multiple_choice':
                schemaFields[question.question_name] = z
                    .string()
                    .min(1, 'Please select an option');
                break;
            case 'linear_scale':
                schemaFields[question.question_name] = z
                    .string()
                    .min(1, 'Please select an option')
                    .pipe(
                        z.coerce.number({
                            invalid_type_error:
                                'This choice is not a valid number',
                        })
                    );
                break;
            case 'list':
                const baseListSchema = z
                    .string()
                    .transform((value) =>
                        value.split('\n').filter((item) => item.trim() !== '')
                    );
                if (question.max_list_items !== null) {
                    schemaFields[question.question_name] = baseListSchema.pipe(
                        z
                            .array(z.string())
                            .max(
                                question.max_list_items,
                                `Please enter at most ${question.max_list_items} item(s)`
                            )
                    );
                } else {
                    schemaFields[question.question_name] = baseListSchema;
                }
                break;
            case 'numerical':
                const baseNumericalSchema = z
                    .string({
                        required_error: 'This field is required',
                    })
                    .trim()
                    .min(1, 'This field is required');
                if (
                    question.min_value !== null &&
                    question.max_value !== null
                ) {
                    schemaFields[question.question_name] =
                        baseNumericalSchema.pipe(
                            z.coerce
                                .number({
                                    invalid_type_error:
                                        'Please enter a valid number',
                                })
                                .gte(
                                    question.min_value,
                                    `Your answer must be greater than or equal to ${question.min_value}`
                                )
                                .lte(
                                    question.max_value,
                                    `Your answer must be less than or equal to ${question.max_value}`
                                )
                        );
                } else if (question.min_value !== null) {
                    schemaFields[question.question_name] =
                        baseNumericalSchema.pipe(
                            z.coerce
                                .number({
                                    invalid_type_error:
                                        'Please enter a valid number',
                                })
                                .gte(
                                    question.min_value,
                                    `Your answer must be greater than or equal to ${question.min_value}`
                                )
                        );
                } else if (question.max_value !== null) {
                    schemaFields[question.question_name] =
                        baseNumericalSchema.pipe(
                            z.coerce
                                .number({
                                    invalid_type_error:
                                        'Please enter a valid number',
                                })
                                .lte(
                                    question.max_value,
                                    `Your answer must be less than or equal to ${question.max_value}`
                                )
                        );
                } else {
                    schemaFields[question.question_name] =
                        baseNumericalSchema.pipe(
                            z.coerce.number({
                                invalid_type_error:
                                    'Please enter a valid number',
                            })
                        );
                }
                break;
            case 'rank':
                schemaFields[question.question_name] = z
                    .record(z.number().int().positive())
                    .refine(
                        (obj) =>
                            Object.keys(obj).length === question.num_selections,
                        `Please rank exactly ${question.num_selections} options`
                    )
                    .transform((obj) =>
                        Object.entries(obj)
                            .sort(([, a], [, b]) => a - b)
                            .map(([option]) => option)
                    );
                break;
            case 'top_k':
                schemaFields[question.question_name] = z
                    .array(z.string())
                    .length(
                        question.min_selections,
                        `Please select exactly ${question.min_selections} option(s)`
                    );
                break;
            case 'yes_no':
                schemaFields[question.question_name] = z
                    .string()
                    .min(1, 'Please select an option');
                break;
        }
    });

    return z.object(schemaFields);
};

function ProjectId() {
    const data = useLoaderData() as SurveyData;
    const actionData = useActionData();
    const submit = useSubmit();
    const surveySchema = createSurveySchema(data.survey.questions);
    const methods = useForm({
        resolver: zodResolver(surveySchema),
    });

    const onSubmit = (responses: any) => {
        console.log(responses);
        submit(
            { responses: responses },
            { method: 'post', encType: 'application/json' }
        );
    };

    const onError = (errors: any) => {
        console.log('Form errors:', errors);
    };

    return (
        <ContentContainer>
            <FormProvider {...methods}>
                <form
                    onSubmit={methods.handleSubmit(onSubmit, onError)}
                    method="post"
                >
                    <div className="flex flex-col gap-4 px-4 md:px-12 w-full lg:w-2/3 mx-auto">
                        {data.survey.questions.map((question, index) => (
                            <Question key={index} question={question} />
                        ))}
                        <button
                            className="w-1/3 px-6 py-2.5 bg-green-700 hover:bg-green-800 transition-colors rounded-md text-white font-medium text-center"
                            type="submit"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </FormProvider>
        </ContentContainer>
    );
}

export default ProjectId;
