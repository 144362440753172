import { Box, Globe, Star, User } from 'react-feather';

function HomeIndex() {
    const linkStyles =
        'text-blue-500 hover:text-blue-600 hover:underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent';

    return (
        <div className="space-y-6">
            <div className="space-y-2 pb-2">
                <h1 className="font-bold text-3xl pb-2">🦜 Welcome to Coop!</h1>
                <p>
                    Coop is our platform for developing and sharing AI-powered
                    research.
                </p>
                <p>
                    Questions or suggestions? Please post a message at our{' '}
                    <a href="https://discord.com/invite/mxAYkjfy9m">Discord</a>{' '}
                    or email us at{' '}
                    <a
                        href="mailto:info@expectedparrot.com"
                        className={linkStyles}
                    >
                        info@expectedparrot.com
                    </a>
                </p>
            </div>
            <div className="space-y-1">
                <h1 className="inline-flex items-center font-bold text-gray-600 dark:text-primary-dark-text text-xl pb-1">
                    <Box className="inline w-5 h-5 mr-2" strokeWidth="2.5" />
                    <span>Content</span>
                </h1>
                <p>
                    - Upload, access, and share content including EDSL objects
                    and notebooks.
                </p>
                <p>- Explore other users' publicly shared content.</p>
                {/* Add videos */}
                <p>
                    Learn how to upload, download, update and delete content
                    with examples:{' '}
                    <a
                        href="https://docs.expectedparrot.com/en/latest/coop.html"
                        target="_blank"
                        className={linkStyles}
                    >
                        Coop documentation page
                    </a>
                    .
                </p>
            </div>
            <div className="space-y-1">
                <h1 className="inline-flex items-center font-bold text-gray-600 dark:text-primary-dark-text text-xl pb-1">
                    <Star className="inline w-5 h-5 mr-2" strokeWidth="2.5" />
                    <span>Services</span>
                </h1>
                <p>- View your and manage your Remote Cache.</p>
                <p>- View your and manage your Remote Inference tasks.</p>
            </div>
            <div className="space-y-1">
                <h1 className="inline-flex items-center font-bold text-gray-600 dark:text-primary-dark-text text-xl pb-1">
                    <User className="inline w-5 h-5 mr-2" strokeWidth="2.5" />
                    <span>Account</span>
                </h1>
                <p>
                    - Get your API key, enable automated remote caching and
                    remote inference, and view your credit balance
                </p>
                <p>- Purchase credits</p>
                <p>- Manage your profile.</p>
            </div>
        </div>
    );
}

export default HomeIndex;
