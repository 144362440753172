import { Outlet, useOutletContext } from 'react-router-dom';
import ContentContainer from '../base/ContentContainer';
import Sidebar from './Sidebar';

function Admin() {
    const isDarkMode: boolean = useOutletContext();

    return (
        <div className="h-full w-full flex">
            <Sidebar />
            <div className="h-full w-full flex flex-col grow items-stretch justify-start overflow-x-hidden">
                <ContentContainer>
                    <Outlet context={isDarkMode} />
                </ContentContainer>
            </div>
        </div>
    );
}

export default Admin;
