import { useLoaderData } from 'react-router-dom';
import * as Tabs from '@radix-ui/react-tabs';
import DemographicForm from './DemographicForm';

interface RespondData {
    is_authenticated: boolean;
    is_beta_user: boolean;
    demographics: {
        age: number;
    };
}

function Respond() {
    const data = useLoaderData() as RespondData;

    return (
        <Tabs.Root defaultValue="surveys">
            <Tabs.List
                className="flex gap-x-2 mb-4 border-b border-gray-200 dark:border-gray-700"
                aria-label="Switch tabs"
            >
                <Tabs.Trigger
                    className="p-4 rounded-t-lg border-b-2 text-sm font-medium text-center data-[state=active]:text-blue-600 data-[state=active]:border-blue-600 data-[state=inactive]:border-transparent dark:data-[state=active]:text-primary-dark-text-accent dark:data-[state=active]:border-primary-dark-text-accent"
                    value="surveys"
                >
                    Surveys
                </Tabs.Trigger>
                <Tabs.Trigger
                    className="p-4 rounded-t-lg border-b-2 text-sm font-medium text-center data-[state=active]:text-blue-600 data-[state=active]:border-blue-600 data-[state=inactive]:border-transparent dark:data-[state=active]:text-primary-dark-text-accent dark:data-[state=active]:border-primary-dark-text-accent"
                    value="demographics"
                >
                    Demographics
                </Tabs.Trigger>
            </Tabs.List>
            <Tabs.Content value="surveys">
                Surveys that you can respond to will appear here.
                <br />
                <br />
                This feature is coming soon!
            </Tabs.Content>
            <Tabs.Content value="demographics">
                We're in the process of creating an agent pool based on human
                data!
                <br />
                <br />
                If you'd like other users to create agents based on your data,
                you can start filling out the fields on this page.
                <br />
                <br />
                <DemographicForm demographics={data.demographics} />
            </Tabs.Content>
        </Tabs.Root>
    );
}

export default Respond;
