import { QuestionSchemaDefinition } from './types';

const questionSchema: QuestionSchemaDefinition = [
    {
        name: 'Budget',
        type: 'budget',
        structure: {
            options: [],
            budgetSum: '',
        },
        constraints: {
            defaultOptions: [{ text: 'Option 1' }, { text: 'Option 2' }],
            minNumOptions: 2,
            maxNumOptions: 200,
        },
    },
    {
        name: 'Checkbox',
        type: 'checkbox',
        structure: {
            options: [],
            minSelections: '',
            maxSelections: '',
        },
        constraints: {
            defaultOptions: [{ text: 'Option 1' }, { text: 'Option 2' }],
            minNumOptions: 2,
            maxNumOptions: 200,
        },
    },
    {
        name: 'Free text',
        type: 'free_text',
        structure: {},
        constraints: {},
    },
    {
        name: 'Likert',
        type: 'likert_five',
        structure: {
            options: [],
        },
        constraints: {
            defaultOptions: [
                { text: 'Strongly disagree' },
                { text: 'Disagree' },
                { text: 'Neutral' },
                { text: 'Agree' },
                { text: 'Strongly agree' },
            ],
            minNumOptions: 2,
            maxNumOptions: 200,
        },
    },
    {
        name: 'Linear scale',
        type: 'linear_scale',
        structure: {
            options: [],
        },
        constraints: {
            defaultOptions: [
                { text: '1', label: 'I hate it' },
                { text: '2', label: '' },
                { text: '3', label: '' },
                { text: '4', label: '' },
                { text: '5', label: 'I love it' },
            ],
            minNumOptions: 2,
            maxNumOptions: 200,
        },
    },
    {
        name: 'List',
        type: 'list',
        structure: {
            maxListItems: '',
        },
        constraints: {},
    },
    {
        name: 'Multiple choice',
        type: 'multiple_choice',
        structure: {
            options: [],
        },
        constraints: {
            defaultOptions: [{ text: 'Option 1' }, { text: 'Option 2' }],
            minNumOptions: 2,
            maxNumOptions: 200,
        },
    },
    {
        name: 'Numerical',
        type: 'numerical',
        structure: {
            minValue: '',
            maxValue: '',
        },
        constraints: {},
    },
    {
        name: 'Rank',
        type: 'rank',
        structure: {
            options: [],
            numSelections: '',
        },
        constraints: {
            defaultOptions: [{ text: 'Option 1' }, { text: 'Option 2' }],
            minNumOptions: 2,
            maxNumOptions: 200,
        },
    },
    {
        name: 'Top k',
        type: 'top_k',
        structure: {
            options: [],
            minSelections: '',
            maxSelections: '',
        },
        constraints: {
            defaultOptions: [{ text: 'Option 1' }, { text: 'Option 2' }],
            minNumOptions: 2,
            maxNumOptions: 200,
        },
    },
    {
        name: 'Yes/no',
        type: 'yes_no',
        structure: {
            options: [],
        },
        constraints: {
            defaultOptions: [{ text: 'Yes' }, { text: 'No' }],
            minNumOptions: 2,
            maxNumOptions: 2,
        },
    },
];

export default questionSchema;
