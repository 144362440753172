import { useState } from 'react';
import DataTableColumnFilter from './DataTableColumnFilter';
import Toggle from '../../../../base/Toggle';

function DataTable({
    columns,
    records,
}: {
    columns: object;
    records: object[];
}) {
    const [isWrapped, setIsWrapped] = useState(false);
    const [columnsToShow, setColumnsToShow] = useState(() => {
        const lst = Object.entries(columns)
            .map(([name, isRequired], index) => {
                return { id: index, name: name, isRequired: isRequired };
            })
            // Arrange columns in alphabetical order
            .sort((a, b) => {
                const colA = a.name.toUpperCase();
                const colB = b.name.toUpperCase();
                if (colA < colB) {
                    return -1;
                }
                if (colA > colB) {
                    return 1;
                }
                return 0;
            });
        return lst;
    });

    const recordData = records.map((record, index) => {
        const fields = Object.entries(record)
            .map(([colName, value], index) => {
                const column = columnsToShow.find(
                    (col) => col.name === colName
                );
                const isRequired = column.isRequired === true;
                const formattedValue =
                    typeof value === 'string' ? value : JSON.stringify(value);
                return {
                    id: index,
                    colName: colName,
                    value: formattedValue,
                    isRequired: isRequired,
                };
            })
            // Arrange fields in alphabetical order by column name
            .sort((a, b) => {
                const colA = a.colName.toUpperCase();
                const colB = b.colName.toUpperCase();
                if (colA < colB) {
                    return -1;
                }
                if (colA > colB) {
                    return 1;
                }
                return 0;
            });
        return { id: index, fields: fields };
    });

    function handleChangeColumnVisibility(
        columnId: number,
        isVisible: boolean
    ) {
        const nextColumnsToShow = [...columnsToShow];
        const colIndex = nextColumnsToShow.findIndex(
            (col) => col.id == columnId
        );
        nextColumnsToShow[colIndex] = {
            ...nextColumnsToShow[colIndex],
            isRequired: isVisible,
        };
        setColumnsToShow(nextColumnsToShow);
    }

    function handleToggleWrap(newIsWrapped: boolean) {
        setIsWrapped(newIsWrapped);
    }

    const wrappedClasses = isWrapped ? '' : 'truncate';

    return (
        <>
            <div className="flex justify-between mb-4">
                <DataTableColumnFilter
                    columns={columnsToShow}
                    changeVisibility={handleChangeColumnVisibility}
                />
                <div className="flex justify-start items-center py-1">
                    <Toggle
                        id="wrap-columns"
                        checked={isWrapped}
                        onCheckedChange={handleToggleWrap}
                        type="button"
                    />
                    <label
                        className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300"
                        htmlFor="wrap-columns"
                    >
                        Wrap columns
                    </label>
                </div>
            </div>
            <div className="relative overflow-x-auto sm:rounded-lg mb-4">
                <table className="w-full text-sm text-left rtl:text-right">
                    <thead className="text-xs text-gray-700 dark:text-secondary-dark-text uppercase bg-gray-50 dark:bg-gray-700">
                        <tr>
                            {columnsToShow.map((col) => {
                                if (col.isRequired) {
                                    return (
                                        <th
                                            key={col.id}
                                            className={`max-w-40 break-words px-6 py-3 ${wrappedClasses}`}
                                        >
                                            {col.name}
                                        </th>
                                    );
                                } else {
                                    return (
                                        <th
                                            key={col.id}
                                            className={`hidden max-w-40 break-words px-6 py-3 ${wrappedClasses}`}
                                        >
                                            {col.name}
                                        </th>
                                    );
                                }
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {recordData.map((record) => (
                            <tr
                                key={record.id}
                                className="bg-white dark:bg-primary-dark-bg hover:bg-gray-50 dark:hover:bg-gray-50/5 border-b dark:border-gray-100/20"
                            >
                                {record.fields.map((field) => {
                                    if (field.isRequired) {
                                        return (
                                            <td
                                                key={field.id}
                                                className={`max-w-40 break-words px-6 py-4 align-top ${wrappedClasses}`}
                                            >
                                                {field.value}
                                            </td>
                                        );
                                    } else {
                                        <td
                                            key={field.id}
                                            className={`hidden max-w-40 break-words px-6 py-4 align-top ${wrappedClasses}`}
                                        >
                                            {field.value}
                                        </td>;
                                    }
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default DataTable;
