import { useLoaderData } from 'react-router-dom';

interface EDSLVersionData {
    is_authenticated: boolean;
    edsl_version: string;
}

function EDSLVersion() {
    const data = useLoaderData() as EDSLVersionData;

    return <div>We're on EDSL version {data.edsl_version}.</div>;
}

export default EDSLVersion;
