import { AlertTriangle, Check } from 'react-feather';
import { ActionResponse } from '../../../types';

function SuccessAlert({ message }: { message: string }) {
    return (
        <div className="flex items-center border px-4 py-3 rounded bg-green-50 border-green-400 text-green-800">
            <Check className="inline w-4 h-4 mr-2" />
            {message}
        </div>
    );
}

function ErrorAlert({ message }: { message: string }) {
    return (
        <div className="flex items-center border px-4 py-3 rounded bg-red-50 border-red-400 text-red-800">
            <AlertTriangle className="inline w-4 h-4 mr-2" />
            {message}
        </div>
    );
}

function ActionAlert({ response }: { response: ActionResponse | null }) {
    if (!response) return null;

    const { success, message } = response;

    if (success) {
        return <SuccessAlert message={message} />;
    } else {
        return <ErrorAlert message={message} />;
    }
}

export default ActionAlert;
