import { Link } from 'react-router-dom';
import { ExternalLink } from 'react-feather';
import CodeBlock from '../../CodeBlock';
import InlineCode from '../../InlineCode';

function CoopObjects() {
    const linkStyles =
        'text-blue-500 hover:text-blue-600 hover:underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent';

    const myContentImageURL = new URL(
        '../../../../../public/assets/getting-started/my-content.png',
        import.meta.url
    ).pathname;

    return (
        <>
            <div className="space-y-2">
                <h1 className="font-bold text-4xl pb-2">Coop: How it works</h1>
                <p className="text-lg">
                    You can store objects that you've created with EDSL on Coop.
                </p>
                <br />
                <h2 className="font-bold text-2xl pb-2">
                    1. Store your Expected Parrot API key
                </h2>
                <p>
                    Go to the{' '}
                    <Link to="/home/api" className={linkStyles}>
                        API page
                    </Link>{' '}
                    of your Account and copy your API key. You will need to
                    provide this key to EDSL to post, download and update
                    objects on Coop.
                </p>
                <p>
                    Then add the following line to your{' '}
                    <InlineCode>.env</InlineCode> file in your current working
                    directory (this is the same file where you store API keys
                    for language models that you use with EDSL):
                    <br />
                    <br />
                    <InlineCode>
                        EXPECTED_PARROT_API_KEY = 'your_api_key_here'
                    </InlineCode>
                    <br />
                    <br />
                    This will load your API key as an environment variable that
                    EDSL can access. You can regenerate your API key (and update
                    your <InlineCode>.env</InlineCode> file) at any time.
                </p>
                <br />
                <h2 className="font-bold text-2xl pb-2">
                    2. Create EDSL objects
                </h2>
                <p>
                    Create objects using the EDSL library, such as{' '}
                    <InlineCode>Agents</InlineCode>,{' '}
                    <InlineCode>Surveys</InlineCode>, and{' '}
                    <InlineCode>Results</InlineCode>.
                    <br />
                    You can even create a <InlineCode>Notebook</InlineCode>,
                    which allows you to share your .ipnyb workflows.
                </p>
                <br />
                <h2 className="font-bold text-2xl pb-2">
                    3. Post objects to the Coop
                </h2>
                <p>
                    Posting objects to the Coop is generally done in one of two
                    ways.
                </p>
                <p>
                    You can use the <InlineCode>push()</InlineCode> method of
                    the object:
                </p>
                <br />
                <CodeBlock>
                    from edsl import QuestionMultipleChoice
                    <br />
                    <br />
                    q = QuestionMultipleChoice.example()
                    <br />
                    <br />
                    q.push(description="This is an example question",
                    visibility="public")
                </CodeBlock>
                <br />
                <p>
                    Alternatively, you can use the{' '}
                    <InlineCode>create()</InlineCode> method of the Coop client:
                </p>
                <br />
                <CodeBlock>
                    from edsl import Coop, QuestionMultipleChoice
                    <br />
                    <br />q = QuestionMultipleChoice.example()
                    <br />
                    <br />c = Coop()
                    <br />
                    <br />
                    c.create(q, description="This is an example question",
                    visibility="public")
                </CodeBlock>
                <br />
                <p>
                    See the{' '}
                    <a
                        href="https://docs.expectedparrot.com/en/latest/coop.html"
                        target="_blank"
                        className={linkStyles}
                    >
                        Coop documentation page
                        <ExternalLink
                            className="inline w-4 h-4 ml-1"
                            strokeWidth="1.5"
                        />
                    </a>{' '}
                    for more examples.
                </p>
                <br />
                <h2 className="font-bold text-2xl pb-2">
                    4. Choose the visibility of your objects
                </h2>
                <p>
                    You can set the visibility of an object when you post it to
                    the Coop, or you can update it later.
                </p>
                <p>There are 3 options:</p>
                <ul className="list-disc list-inside space-y-2">
                    <li>
                        <InlineCode>public</InlineCode>: share with everyone
                    </li>
                    <li>
                        <InlineCode>private</InlineCode>: share with no one
                    </li>
                    <li>
                        <InlineCode>unlisted</InlineCode>: share with people who
                        have the link
                    </li>
                </ul>
                <p>By default, objects are posted as unlisted.</p>
                <p>
                    See the{' '}
                    <a
                        href="https://docs.expectedparrot.com/en/latest/coop.html"
                        target="_blank"
                        className={linkStyles}
                    >
                        Coop documentation page
                        <ExternalLink
                            className="inline w-4 h-4 ml-1"
                            strokeWidth="1.5"
                        />
                    </a>{' '}
                    for details and examples for setting the visibility of an
                    object.
                </p>
                <br />
                <h2 className="font-bold text-2xl pb-2">5. Explore content</h2>
                <p>
                    Go to the{' '}
                    <Link to="/content" className={linkStyles}>
                        Coop content page
                    </Link>{' '}
                    to see the content that you've uploaded.
                </p>
                <p>
                    You can also search for other users' public content by
                    clicking on <span className="font-bold">Explore</span>. If
                    you find something interesting, you can copy their code and
                    examples to your own workspace to try it out.
                </p>
                <br />
                <img
                    src={myContentImageURL}
                    alt="Screenshot of the Coop content page. It shows the example question from the tutorial"
                    className="mx-auto"
                />
            </div>
        </>
    );
}

export default CoopObjects;
