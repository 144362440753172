import { useFetcher } from 'react-router-dom';
import Toggle from '../../../base/Toggle';

interface EDSLSettingsToggleProps {
    label: string;
    id: 'remote-caching' | 'remote-inference' | 'remote-logging';
    defaultValue: boolean;
    name: string;
    value: string;
}

function EDSLSettingsToggle({
    label,
    id,
    defaultValue,
    name,
    value,
}: EDSLSettingsToggleProps) {
    const fetcher = useFetcher();

    const checked = fetcher.formData
        ? fetcher.formData.get('value') === 'true'
        : defaultValue;

    function handleToggle(newValue: boolean) {
        fetcher.submit({ intent: id, value: newValue }, { method: 'post' });
    }

    return (
        <div className="flex justify-start items-center space-x-10 py-1">
            <label className="w-40" htmlFor={id}>
                {label}
            </label>
            <Toggle
                id={id}
                checked={checked}
                onCheckedChange={handleToggle}
                name={name}
                value={value}
            />
        </div>
    );
}

export default EDSLSettingsToggle;
