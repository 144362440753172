import { redirect } from 'react-router-dom';

export async function action({ request }) {
    const formData = await request.formData();
    let intent = formData.get('intent');

    let response: Response;

    if (intent === 'update_username') {
        const username = formData.get('new_username');
        response = await fetch('/api/update-username', {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username }),
        });
    }
    if (response.ok) {
        return redirect('/home');
    } else if (response.status === 422) {
        const json = await response.json();
        return 'Pydantic error.';
    } else if (response.status === 504) {
        return 'No response from server.';
    } else {
        const errorData = await response.json();
        return errorData.detail;
    }
}
