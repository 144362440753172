export * from './Admin';
export { default } from './Admin';
export {
    default as AdminConfigVars,
    loader as adminConfigVarsLoader,
    action as adminConfigVarsAction,
} from './routes/ConfigVars';
export {
    default as AdminLogin,
    loader as adminLoginLoader,
} from './routes/Login';
export {
    default as AdminEDSLVersion,
    loader as adminEDSLVersionLoader,
} from './routes/EDSLVersion';
export {
    default as AdminObjects,
    loader as adminObjectsLoader,
    action as adminObjectsAction,
} from './routes/Objects';
export {
    default as AdminStaleObjects,
    action as adminStaleObjectsAction,
} from './routes/StaleObjects';
export {
    default as AdminUsers,
    loader as adminUsersLoader,
    action as adminUsersAction,
} from './routes/Users';
export {
    default as AdminUserStatistics,
    loader as adminUserStatisticsLoader,
} from './routes/UserStatistics';
