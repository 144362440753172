import DataTable from './DataTable';

function ScenarioList({
    columns,
    records,
}: {
    columns: object;
    records: object[];
}) {
    return <DataTable columns={columns} records={records} />;
}

export default ScenarioList;
