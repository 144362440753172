import { useLoaderData } from 'react-router-dom';
import Message from '../base/Message';

function VerifyEmail() {
    const { message } = useLoaderData() as { message: string };

    return (
        <Message
            title="Email verification"
            mainMessage={message}
            redirectUrl="/login"
        />
    );
}

export default VerifyEmail;
