import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Check, Sliders } from 'react-feather';

function DataTableColumnFilter({
    columns,
    changeVisibility,
}: {
    columns: { id: number; name: string; isRequired: any }[];
    changeVisibility: (columnId: number, isVisible: boolean) => void;
}) {
    const btnBaseStyles =
        'py-2.5 px-5 text-sm font-medium focus:outline-none bg-white border-gray-200 hover:bg-gray-100 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-500 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700';

    return (
        <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
                <button
                    aria-label="Select columns to display"
                    className={`border rounded-lg hover:text-blue-700 dark:hover:text-white ${btnBaseStyles}`}
                    type="button"
                >
                    <Sliders className="inline w-4 h-4 mr-2" strokeWidth="2" />
                    Columns
                </button>
            </DropdownMenu.Trigger>

            <DropdownMenu.Portal>
                <DropdownMenu.Content
                    className="w-64 bg-white rounded-md"
                    sideOffset={5}
                >
                    <div className="p-3 border-b border-gray-200 rounded-t-lg bg-gray-50"></div>
                    <div className="max-h-48 overflow-y-scroll px-2 py-2">
                        {columns.map((column) => (
                            <DropdownMenu.CheckboxItem
                                key={column.id}
                                className="min-w-min w-full px-2 py-2 text-sm font-medium text-gray-900 rounded data-[highlighted]:bg-blue-400"
                                checked={column.isRequired}
                                onCheckedChange={(isVisible) =>
                                    changeVisibility(column.id, isVisible)
                                }
                                onSelect={(event) => event.preventDefault()}
                            >
                                <DropdownMenu.ItemIndicator className="DropdownMenuItemIndicator">
                                    <Check
                                        className="inline w-4 h-4 mr-2"
                                        strokeWidth="2"
                                    />
                                </DropdownMenu.ItemIndicator>
                                {column.name}
                            </DropdownMenu.CheckboxItem>
                        ))}
                    </div>
                    <div className="p-3 border-t border-gray-200 rounded-b-lg bg-gray-50"></div>
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    );
}

export default DataTableColumnFilter;
