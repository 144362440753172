interface InlineCodeProps {
    children: React.ReactNode;
}

function CodeBlock({ children }: InlineCodeProps) {
    return (
        <span className="p-1 bg-gray-100 dark:bg-gray-100/10 font-mono">
            {children}
        </span>
    );
}

export default CodeBlock;
