import { redirect } from 'react-router-dom';

export async function action({ request }) {
    const data = await request.json();
    const configVars = JSON.parse(data.configVars);

    const response = await fetch('/api/admin/config-vars', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ config_vars: configVars }),
    });

    if (response.ok) {
        return {
            success: true,
            message: 'Config var update successful!',
        };
    } else if (response.status === 401) {
        return redirect('/login');
    } else if (response.status === 422) {
        return { success: false, message: 'Pydantic error.' };
    } else if (response.status === 504) {
        return { success: false, message: 'No response from server.' };
    } else {
        const errorData = await response.json();
        return { success: false, message: errorData.detail };
    }
}
