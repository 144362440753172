import { useLoaderData } from 'react-router-dom';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';
import { formatDate } from '../../../../utils';

interface UserStatisticsData {
    is_authenticated: boolean;
    weekly_registrations: {
        week: string;
        verified: number;
        unverified: number;
    }[];
    weekly_uploads_downloads: {
        week: string;
        uploads: number;
        downloads: number;
    }[];
    weekly_uploaders_downloaders: {
        week: string;
        distinct_uploaders: number;
        distinct_downloaders: number;
    }[];
    weekly_job_runs: {
        week: string;
        job_runs: number;
    }[];
    weekly_job_runners: {
        week: string;
        job_runners: number;
    }[];
    weekly_purchases: {
        week: string;
        credits_purchased: number;
    }[];
}

function UserStatistics() {
    const data = useLoaderData() as UserStatisticsData;

    const todayUnixTimestamp = Math.floor(Date.now() / 1000);

    return (
        <div>
            <p className="mb-4 text-lg">
                These graphs cover the last year up to{' '}
                {formatDate(todayUnixTimestamp, 'YYYY-MM-DD')}. Data is
                aggregated on a weekly level.
            </p>
            <h3 className="mb-4 text-lg">User Registrations</h3>
            <div className="h-64">
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        width={500}
                        height={300}
                        data={data.weekly_registrations}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="week" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line
                            type="monotone"
                            dataKey="verified"
                            stroke="#2563eb"
                            strokeWidth={2}
                        />
                        <Line
                            type="monotone"
                            dataKey="unverified"
                            stroke="#60a5fa"
                            strokeWidth={2}
                        />
                    </LineChart>
                </ResponsiveContainer>
            </div>
            <h3 className="mb-4 text-lg">Object Uploads and Downloads</h3>
            <div className="h-64">
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        width={500}
                        height={300}
                        data={data.weekly_uploads_downloads}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="week" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line
                            type="monotone"
                            dataKey="uploads"
                            stroke="#2563eb"
                            strokeWidth={2}
                        />
                        <Line
                            type="monotone"
                            dataKey="downloads"
                            stroke="#60a5fa"
                            strokeWidth={2}
                        />
                    </LineChart>
                </ResponsiveContainer>
            </div>
            <h3 className="mb-4 text-lg">
                Distinct Users Who Uploaded and Downloaded Objects
            </h3>
            <div className="h-64">
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        width={500}
                        height={300}
                        data={data.weekly_uploaders_downloaders}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="week" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line
                            type="monotone"
                            dataKey="distinct_uploaders"
                            stroke="#2563eb"
                            strokeWidth={2}
                        />
                        <Line
                            type="monotone"
                            dataKey="distinct_downloaders"
                            stroke="#60a5fa"
                            strokeWidth={2}
                        />
                    </LineChart>
                </ResponsiveContainer>
            </div>
            <h3 className="mb-4 text-lg">Job Runs</h3>
            <div className="h-64">
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        width={500}
                        height={300}
                        data={data.weekly_job_runs}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="week" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line
                            type="monotone"
                            dataKey="job_runs"
                            stroke="#2563eb"
                            strokeWidth={2}
                        />
                    </LineChart>
                </ResponsiveContainer>
            </div>
            <h3 className="mb-4 text-lg">Job Runners</h3>
            <div className="h-64">
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        width={500}
                        height={300}
                        data={data.weekly_job_runners}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="week" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line
                            type="monotone"
                            dataKey="job_runners"
                            stroke="#2563eb"
                            strokeWidth={2}
                        />
                    </LineChart>
                </ResponsiveContainer>
            </div>
            <h3 className="mb-4 text-lg">Credit Purchases</h3>
            <div className="h-64">
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        width={500}
                        height={300}
                        data={data.weekly_purchases}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="week" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line
                            type="monotone"
                            dataKey="credits_purchased"
                            stroke="#2563eb"
                            strokeWidth={2}
                        />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}

export default UserStatistics;
