import { redirect } from 'react-router-dom';

export async function action({ request }) {
    const formData = await request.formData();
    let intent = formData.get('intent');

    const objectId = formData.get('object_uuid');

    let response: Response;

    if (intent === 'gift') {
        response = await fetch('/api/users/gift', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                recipient_username: formData.get('recipient_username'),
                credits_gifted: formData.get('credits_gifted'),
                gift_note: formData.get('gift_note'),
            }),
        });
    } else if (intent === 'share') {
        response = await fetch('/api/content/share', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: formData.get('email'),
                object_uuid: formData.get('object_uuid'),
            }),
        });
    } else if (intent === 'unshare') {
        response = await fetch('/api/content/unshare', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: formData.get('email'),
                object_uuid: formData.get('object_uuid'),
            }),
        });
    } else if (intent === 'edit') {
        response = await fetch(`/api/content/${objectId}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                description: formData.get('description'),
            }),
        });
    } else if (intent === 'delete') {
        response = await fetch(`/api/content/${objectId}`, {
            method: 'DELETE',
        });
    } else if (intent === 'toggle_star') {
        response = await fetch(`/api/content/toggle-star/${objectId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    if (response.ok && intent === 'delete') {
        return redirect('/content');
    } else if (response.ok) {
        const successData = await response.json();
        return { success: true, message: successData.message };
    } else if (response.status === 422 && intent === 'gift') {
        const errorData = await response.json();
        if (errorData.detail[0].type === 'int_parsing') {
            return {
                success: false,
                message: 'You must enter an integer amount.',
            };
        } else {
            return { success: false, message: 'Pydantic error' };
        }
    } else if (response.status === 422) {
        return { success: false, message: 'Pydantic error.' };
    } else if (response.status === 504) {
        return { success: false, message: 'No response from server.' };
    } else {
        const errorData = await response.json();
        return { success: false, message: errorData.detail };
    }
}
