export async function action({ request }) {
    const formData = await request.formData();

    const response = await fetch('/api/register', {
        method: 'POST',
        body: formData,
    });

    if (response.ok) {
        return {
            success: true,
            message:
                'Registration successful! Please check for your verification email.',
        };
    } else if (response.status === 504) {
        return { success: false, message: 'No response from server.' };
    } else {
        const errorData = await response.json();
        return { success: false, message: errorData.detail };
    }
}
