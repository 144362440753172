import { redirect } from 'react-router-dom';

export async function action({ request }) {
    const formData = await request.formData();
    let intent = formData.get('intent');

    const objectId = formData.get('object_uuid');

    let response: Response;

    if (intent === 'share') {
        response = await fetch('/api/content/share', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: formData.get('email'),
                object_uuid: formData.get('object_uuid'),
            }),
        });
    } else if (intent === 'unshare') {
        response = await fetch('/api/content/unshare', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: formData.get('email'),
                object_uuid: formData.get('object_uuid'),
            }),
        });
    } else if (intent === 'edit') {
        response = await fetch(`/api/content/${objectId}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                description: formData.get('description'),
            }),
        });
    } else if (intent === 'delete') {
        response = await fetch(`/api/content/${objectId}`, {
            method: 'DELETE',
        });
    }

    if (response.ok && intent === 'delete') {
        return redirect('/content');
    } else if (response.ok) {
        const successData = await response.json();
        return successData.message;
    } else if (response.status === 422) {
        return 'Pydantic error.';
    } else if (response.status === 504) {
        return 'No response from server.';
    } else {
        const errorData = await response.json();
        return errorData.detail;
    }
}
