import { useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import Header from './Header';

function App() {
    const [isDarkMode, setIsDarkMode] = useState<boolean>(() => {
        const currentTheme = localStorage.getItem('theme');
        if (currentTheme === 'dark') {
            return true;
        } else {
            return false;
        }
    });

    useEffect(() => {
        if (isDarkMode) {
            document.documentElement.classList.add('dark');
            document.documentElement.style.colorScheme = 'dark';
        } else {
            document.documentElement.classList.remove('dark');
            document.documentElement.style.colorScheme = 'light';
        }
    }, [isDarkMode]);

    function toggleDarkMode(value: boolean) {
        setIsDarkMode(value);
        if (value === true) {
            localStorage.setItem('theme', 'dark');
        } else {
            localStorage.setItem('theme', 'light');
        }
    }

    return (
        <div className="h-full flex flex-col">
            <Header isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />
            <main className="h-full flex-1 overflow-y-hidden flex flex-col items-center dark:bg-primary-dark-bg dark:text-primary-dark-text">
                <Outlet context={isDarkMode} />
            </main>
            <footer className="text-gray-500 border-t border-gray-200 dark:bg-primary-dark-bg dark:text-primary-dark-text dark:border-slate-700">
                <div className="flex flex-col sm:flex-row justify-center items-center space-x-1 sm:space-x-2 py-5 sm:py-4 text-sm sm:text-base">
                    <p>© 2024 Expected Parrot, Inc.</p>
                    <span className="hidden sm:inline">·</span>
                    <div className="space-x-1 sm:space-x-2">
                        <a
                            href="/terms"
                            target="_blank"
                            className="hover:text-green-700 dark:hover:text-primary-dark-text-accent"
                        >
                            Terms
                        </a>
                        <span className="sm:inline">·</span>
                        <Link
                            to="/privacy"
                            className="hover:text-green-700 dark:hover:text-primary-dark-text-accent"
                        >
                            Privacy
                        </Link>
                        <span className="sm:inline">·</span>
                        <a
                            href="mailto:info@expectedparrot.com"
                            className="hover:text-green-700 dark:hover:text-primary-dark-text-accent"
                        >
                            info@expectedparrot.com
                        </a>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default App;
