import { json, redirect } from 'react-router-dom';

export async function loader() {
    const response = await fetch('/api/respond', {
        method: 'GET',
    });

    if (response.ok) {
        return response;
    } else if (response.status === 401) {
        return redirect('/login');
    } else if (response.status === 504) {
        throw json(
            { message: 'No response from server.' },
            { status: response.status }
        );
    } else {
        const errorData = await response.json();
        throw json({ message: errorData.detail }, { status: response.status });
    }
}
