import * as Avatar from '@radix-ui/react-avatar';

function ObjectTableAvatar({
    ownerUsername,
    ownerProfilePhoto,
}: {
    ownerUsername: string | null;
    ownerProfilePhoto: string | null;
}) {
    return (
        <Avatar.Root className="shrink-0 inline-flex size-6 select-none items-center justify-center overflow-hidden rounded-full bg-green-100 align-middle">
            <Avatar.Image
                className="size-full rounded-full object-cover"
                src={ownerProfilePhoto}
                alt="Profile photo"
            />
            <Avatar.Fallback
                className="flex size-full items-center justify-center bg-green-100 text-[11px] leading-1 font-medium text-green-800"
                delayMs={600}
            >
                {ownerUsername ? ownerUsername.slice(0, 2).toUpperCase() : 'AN'}
            </Avatar.Fallback>
        </Avatar.Root>
    );
}

export default ObjectTableAvatar;
