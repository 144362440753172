import ContentContainer from '../base/ContentContainer';

function Privacy() {
    return (
        <ContentContainer>
            <div className="flex flex-col items-center justify-center w-full space-y-3 pt-32 px-4">
                <h1 className="text-4xl font-bold text-green-500 dark:text-primary-dark-text drop-shadow-sm">
                    Privacy
                </h1>
                <p className="text-2xl text-center font-bold text-blue-900 dark:text-primary-dark-text-accent drop-shadow-sm">
                    Last updated: May 2, 2024
                </p>
            </div>
            <div className="space-y-8 text-xl p-12">
                <div className="space-y-4">
                    <p className="text-center">Coming soon!</p>
                </div>
            </div>
        </ContentContainer>
    );
}

export default Privacy;
