import { useFetcher } from 'react-router-dom';

function StaleObjects() {
    const fetcher = useFetcher();

    return (
        <fetcher.Form method="post">
            <button className="flex gap-2 px-6 py-2.5 bg-green-700 hover:bg-green-800 transition-colors rounded-md text-white font-medium">
                Check for stale objects
            </button>
        </fetcher.Form>
    );
}

export default StaleObjects;
