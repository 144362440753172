import { useLoaderData } from 'react-router-dom';
import { formatDate } from '../../../../utils';
import * as Avatar from '@radix-ui/react-avatar';
import BetaToggle from './BetaToggle';
import ChangeProfilePhotoModal from './ChangeProfilePhotoModal';
import DeleteAccountModal from './DeleteAccountModal';
import EditUsernameForm from './EditUsernameForm';

interface ProfileData {
    is_authenticated: boolean;
    is_beta_user: boolean;
    email: string;
    created_ts: number;
    username: string;
    base_64_profile_photo: string;
}

function Profile() {
    const profileData = useLoaderData() as ProfileData;

    const formattedDate = formatDate(profileData.created_ts, 'MMMM D, YYYY');

    return (
        <>
            <div className="space-y-2">
                <h1 className="font-bold text-3xl pb-2">Profile</h1>
            </div>
            <div className="space-y-2 max-w-3xl">
                <h2 className="font-bold text-2xl">Information</h2>
                <div className="w-full space-y-2 pt-4">
                    <Avatar.Root className="inline-flex size-40 select-none items-center justify-center overflow-hidden rounded-full bg-green-100 align-middle">
                        <Avatar.Image
                            className="size-full rounded-full object-cover"
                            src={profileData.base_64_profile_photo}
                            alt="Profile photo"
                        />
                        <Avatar.Fallback
                            className="flex size-full items-center justify-center bg-green-100 text-5xl leading-1 font-medium text-green-800"
                            delayMs={600}
                        >
                            {profileData.username
                                ? profileData.username.slice(0, 2).toUpperCase()
                                : 'AN'}
                        </Avatar.Fallback>
                    </Avatar.Root>
                    <div>
                        <ChangeProfilePhotoModal
                            username={profileData.username}
                            profilePhoto={profileData.base_64_profile_photo}
                        >
                            <button
                                type="button"
                                name="intent"
                                className="mt-2 px-3 py-2 bg-green-600 hover:bg-green-700 border-2 border-green-600 hover:border-green-700 rounded-md text-white font-semibold"
                            >
                                Change profile photo
                            </button>
                        </ChangeProfilePhotoModal>
                    </div>
                    <div className="flex flex-col md:flex-row md:items-center">
                        <div className="md:w-1/3 pr-4">
                            <span className="text-blue-500 dark:text-primary-dark-text-accent font-bold py-2">
                                Account Created
                            </span>
                        </div>
                        <div className="grow py-2">{formattedDate}</div>
                    </div>
                    <div className="flex flex-col md:flex-row md:items-center">
                        <div className="md:w-1/3 pr-4">
                            <span className="text-blue-500 dark:text-primary-dark-text-accent font-bold py-2">
                                Email
                            </span>
                        </div>
                        <div className="grow py-2">{profileData.email}</div>
                    </div>
                    <EditUsernameForm currentUsername={profileData.username} />
                    <div className="mt-4 text-base italic">
                        Your username is displayed with any content that you
                        post, unless you choose to post anonymously.
                    </div>
                </div>
            </div>
            <div className="space-y-2">
                <h2 className="font-bold text-2xl">Beta</h2>
                <p>Get access to beta features.</p>
                <BetaToggle defaultValue={profileData.is_beta_user} />
            </div>
            <div className="space-y-2">
                <h2 className="font-bold text-2xl">Danger Zone</h2>
                <p>This action is irreversible!</p>
                <DeleteAccountModal>
                    <button
                        type="submit"
                        name="intent"
                        value="delete_account"
                        className="mt-2 bg-red-600 hover:bg-red-700 border-2 border-red-600 hover:border-red-700 rounded-md px-3 py-2 text-white font-semibold"
                    >
                        Delete account
                    </button>
                </DeleteAccountModal>
            </div>
        </>
    );
}

export default Profile;
