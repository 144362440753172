import { Link, useFetcher } from 'react-router-dom';
import * as Tooltip from '@radix-ui/react-tooltip';
import { AlertCircle, Check, Eye, Download, Star } from 'react-feather';
import { ObjectTableDropdown } from './ObjectTableButtons';
import ObjectTableAvatar from './ObjectTableAvatar';
import ObjectTypeChip from '../ObjectTypeChip';
import { Object } from '../../../types';

function ObjectTableFavoriteBtn({
    objectId,
    isStarredByUser,
}: {
    objectId: string;
    isStarredByUser: boolean;
}) {
    const fetcher = useFetcher();

    return (
        <fetcher.Form method="post" className="flex">
            <input type="hidden" name="object_uuid" value={objectId} />
            <button
                type="submit"
                name="intent"
                value="toggle_star"
                aria-label="Add object to favorites"
            >
                <Star
                    className={`inline w-4 h-4 ${
                        isStarredByUser &&
                        'fill-black stroke-black dark:fill-yellow-300 dark:stroke-yellow-300'
                    }`}
                    strokeWidth="2"
                />
            </button>
        </fetcher.Form>
    );
}

function ObjectTableRow({
    isAdmin,
    pageType,
    object,
    isSelected,
    selectRow,
}: {
    isAdmin: boolean;
    pageType: 'home' | 'explore';
    object: Object;
    isSelected: boolean;
    selectRow: (rowId: string) => void;
}) {
    const styles = isSelected
        ? 'bg-[#c9e6fc] dark:bg-[#2ca4ff33]'
        : 'hover:bg-gray-100 dark:hover:bg-gray-50/5';

    const objectLink = isAdmin
        ? `/admin/objects/${object.id}`
        : `/content/${object.id}`;

    return (
        <tr
            onClick={() => selectRow(object.id)}
            onDoubleClick={() => window.open(objectLink, '_blank')}
            className={`border-b-2 dark:border-gray-100/20 dark:text-secondary-dark-text cursor-pointer ${styles}`}
        >
            {/* Add star column */}
            <td className="py-3 px-2 w-8">
                <ObjectTableFavoriteBtn
                    objectId={object.id}
                    isStarredByUser={object.is_starred_by_user}
                />
            </td>
            <td className="py-3 px-2 w-8 text-orange-400 text-sm whitespace-nowrap">
                <ObjectTypeChip objectType={object.object_type} />
            </td>
            <td className="py-3 px-3 w-96 overflow-x-auto text-left font-medium">
                {object.description ? (
                    <p>{object.description}</p>
                ) : (
                    <p className="italic">No description provided</p>
                )}
                <div className="flex items-center gap-x-3 mt-2 text-xs">
                    <span className="flex items-center gap-x-1 text-gray-500">
                        <Eye
                            className={`inline w-3 h-3 ${
                                object.is_viewed_by_user
                                    ? 'text-blue-400'
                                    : 'text-gray-300 dark:text-gray-500'
                            }`}
                            strokeWidth="3"
                        />
                        {object.view_count}
                    </span>
                    <span className="flex items-center gap-x-1 text-gray-500">
                        <Download
                            className={`inline w-3 h-3 ${
                                object.is_downloaded_by_user
                                    ? 'text-rose-400'
                                    : 'text-gray-300 dark:text-gray-500'
                            }`}
                            strokeWidth="3"
                        />
                        {object.download_count}
                    </span>
                    <span className="flex items-center gap-x-1 text-gray-500">
                        <Star
                            className={`inline w-3 h-3 ${
                                object.is_starred_by_user
                                    ? 'text-yellow-300'
                                    : 'text-gray-300 dark:text-gray-500'
                            }`}
                            strokeWidth="3"
                        />
                        {object.star_count}
                    </span>
                    {object.object_type === 'results' && object.job_uuid && (
                        <Tooltip.Provider>
                            <Tooltip.Root delayDuration={400}>
                                <Tooltip.Trigger asChild>
                                    <Check
                                        className="w-4 text-green-600"
                                        strokeWidth="2.5"
                                    />
                                </Tooltip.Trigger>
                                <Tooltip.Portal>
                                    <Tooltip.Content
                                        className="px-3 py-2 select-none text-sm font-medium text-white bg-gray-900 dark:bg-gray-700 rounded-lg shadow-sm"
                                        sideOffset={5}
                                    >
                                        This job was run by Expected Parrot
                                        <Tooltip.Arrow className="fill-gray-900 dark:fill-gray-700" />
                                    </Tooltip.Content>
                                </Tooltip.Portal>
                            </Tooltip.Root>
                        </Tooltip.Provider>
                    )}
                    {object.is_stale && (
                        <Tooltip.Provider>
                            <Tooltip.Root delayDuration={400}>
                                <Tooltip.Trigger asChild>
                                    <AlertCircle className="w-4 text-orange-400" />
                                </Tooltip.Trigger>
                                <Tooltip.Portal>
                                    <Tooltip.Content
                                        className="px-3 py-2 select-none text-sm font-medium text-white bg-gray-900 dark:bg-gray-700 rounded-lg shadow-sm"
                                        sideOffset={5}
                                    >
                                        This object is stale
                                        <Tooltip.Arrow className="fill-gray-900 dark:fill-gray-700" />
                                    </Tooltip.Content>
                                </Tooltip.Portal>
                            </Tooltip.Root>
                        </Tooltip.Provider>
                    )}
                </div>
            </td>
            <td className="py-3 px-3 w-32">{object.created_time_from_now}</td>
            <td className="py-3 px-3 w-32">
                {object.last_updated_time_from_now}
            </td>
            <td className="py-3 px-4 w-32">
                {/* If the owner has a username, link to their public profile */}
                {object.owner_username ? (
                    <Link
                        className="flex align-center gap-2 group"
                        to={`/users/${object.owner_username}`}
                    >
                        <ObjectTableAvatar
                            ownerUsername={object.owner_username}
                            ownerProfilePhoto={object.owner_profile_photo}
                        />
                        <span className="truncate group-hover:underline group-hover:underline-offset-2">
                            {object.is_owned_by_user
                                ? 'You'
                                : object.owner_username || 'anonymous'}
                        </span>
                    </Link>
                ) : (
                    <div className="flex align-center gap-2">
                        <ObjectTableAvatar
                            ownerUsername={object.owner_username}
                            ownerProfilePhoto={object.owner_profile_photo}
                        />
                        <span className="truncate">
                            {object.is_owned_by_user
                                ? 'You'
                                : object.owner_username || 'anonymous'}
                        </span>
                    </div>
                )}
            </td>
            {/* Visibility on the Explore page is always public - no need for column */}
            {pageType === 'home' && (
                <td className="py-3 px-3 w-32">{object.visibility}</td>
            )}
            <td className="pt-3 px-4 w-16">
                <ObjectTableDropdown object={object} />
            </td>
        </tr>
    );
}

export default ObjectTableRow;
