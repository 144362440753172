import { Form, Link, useActionData } from 'react-router-dom';
import ContentContainer from '../base/ContentContainer';

function ForgotPassword() {
    const message = useActionData() as string;

    const lightInputStyles =
        'w-full bg-white focus:outline-none mt-1 border border-gray-400 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 block p-2.5';
    const darkInputStyles =
        'dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500';
    const inputStyles = `${lightInputStyles} ${darkInputStyles}`;

    return (
        <ContentContainer>
            <div className="w-full max-w-sm m-auto flex flex-col gap-y-6 items-center items-stretch p-8 border-solid border-2 border-gray-300 dark:border-primary-dark-border rounded-lg text-sm shadow-md">
                <p className="font-semibold text-2xl text-center">
                    Reset Your Password
                </p>
                <p>
                    Type in your email address below and we'll send you an email
                    with instructions on how to create a new password.
                </p>
                <Form method="post" className="flex flex-col gap-4">
                    <fieldset>
                        <label htmlFor="reset-password-email" className="block">
                            Email
                        </label>
                        <input
                            type="email"
                            id="reset-password-email"
                            name="reset_password_email"
                            required
                            className={inputStyles}
                        />
                    </fieldset>
                    <button className="self-stretch w-full bg-blue-400 text-white rounded-md p-2 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:ring focus:ring-blue-200 active:bg-blue-700">
                        Send instructions
                    </button>
                </Form>
                {message && (
                    <div className="bg-red-100 border border-red-400 px-4 py-3 rounded text-red-700 text-center">
                        {message}
                    </div>
                )}
                <Link
                    to="/login"
                    className="block text-center font-semibold text-gray-500 hover:text-gray-700 dark:hover:text-primary-dark-text-accent"
                >
                    Back to login
                </Link>
            </div>
        </ContentContainer>
    );
}

export default ForgotPassword;
