import { useFetcher } from 'react-router-dom';
import * as Popover from '@radix-ui/react-popover';
import { Check, RotateCw, XOctagon, X } from 'react-feather';

function RetryBtn({ jobId }: { jobId: string }) {
    const fetcher = useFetcher();

    return (
        <Popover.Root>
            <Popover.Trigger asChild>
                <button onClick={(event) => event.stopPropagation()}>
                    <RotateCw
                        className="inline w-4 h-4 ml-1 hover:text-blue-400"
                        strokeWidth="2"
                    />
                </button>
            </Popover.Trigger>
            <Popover.Portal>
                <Popover.Content
                    className="bg-white dark:bg-gray-700 rounded-lg shadow"
                    sideOffset={5}
                >
                    {fetcher.data ? (
                        <div className="flex flex-col gap-y-4 p-4 text-center">
                            {fetcher.data}
                            <div className="flex justify-center gap-x-4">
                                <Popover.Close asChild>
                                    <button
                                        onClick={(event) =>
                                            event.stopPropagation()
                                        }
                                        className="flex gap-x-2 hover:bg-gray-100 dark:hover:bg-gray-300/10 border border-gray-200 dark:border-gray-500 py-2 px-4 rounded focus:shadow-outline"
                                    >
                                        <X strokeWidth="1.5" />
                                        Close
                                    </button>
                                </Popover.Close>
                            </div>
                        </div>
                    ) : (
                        <div className="flex flex-col gap-y-4 p-4 text-center">
                            Do you want to retry this job?
                            <div className="flex gap-x-4">
                                <Popover.Close asChild>
                                    <button
                                        onClick={(event) =>
                                            event.stopPropagation()
                                        }
                                        className="flex gap-x-2 hover:bg-gray-100 dark:hover:bg-gray-300/10 border border-gray-200 dark:border-gray-500 py-2 px-4 rounded focus:shadow-outline"
                                    >
                                        <X strokeWidth="1.5" />
                                        Close
                                    </button>
                                </Popover.Close>
                                <fetcher.Form method="post">
                                    <input
                                        type="hidden"
                                        name="job_uuid"
                                        value={jobId}
                                    />
                                    <button
                                        name="intent"
                                        value="retry_job"
                                        onClick={(event) =>
                                            event.stopPropagation()
                                        }
                                        className="flex gap-x-2 bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded focus:shadow-outline"
                                    >
                                        <Check strokeWidth="1.5" />
                                        Retry job
                                    </button>
                                </fetcher.Form>
                            </div>
                        </div>
                    )}
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
}

function CancelBtn({ jobId }: { jobId: string }) {
    const fetcher = useFetcher();

    return (
        <Popover.Root>
            <Popover.Trigger asChild>
                <button onClick={(event) => event.stopPropagation()}>
                    <XOctagon
                        className="inline w-4 h-4 ml-1 hover:text-blue-400"
                        strokeWidth="2"
                    />
                </button>
            </Popover.Trigger>
            <Popover.Portal>
                <Popover.Content
                    className="bg-white dark:bg-gray-700 rounded-lg shadow"
                    sideOffset={5}
                >
                    {fetcher.data ? (
                        <div className="flex flex-col gap-y-4 p-4 text-center">
                            {fetcher.data}
                            <div className="flex justify-center gap-x-4">
                                <Popover.Close asChild>
                                    <button
                                        onClick={(event) =>
                                            event.stopPropagation()
                                        }
                                        className="flex gap-x-2 hover:bg-gray-100 dark:hover:bg-gray-300/10 border border-gray-200 dark:border-gray-500 py-2 px-4 rounded focus:shadow-outline"
                                    >
                                        <X strokeWidth="1.5" />
                                        Close
                                    </button>
                                </Popover.Close>
                            </div>
                        </div>
                    ) : (
                        <div className="flex flex-col gap-y-4 p-4 text-center">
                            Do you want to cancel this job?
                            <div className="flex gap-x-4">
                                <Popover.Close asChild>
                                    <button
                                        onClick={(event) =>
                                            event.stopPropagation()
                                        }
                                        className="flex gap-x-2 hover:bg-gray-100 dark:hover:bg-gray-300/10 border border-gray-200 dark:border-gray-500 py-2 px-4 rounded focus:shadow-outline"
                                    >
                                        <X strokeWidth="1.5" />
                                        Close
                                    </button>
                                </Popover.Close>
                                <fetcher.Form method="post">
                                    <input
                                        type="hidden"
                                        name="job_uuid"
                                        value={jobId}
                                    />
                                    <button
                                        name="intent"
                                        value="cancel_job"
                                        onClick={(event) =>
                                            event.stopPropagation()
                                        }
                                        className="flex gap-x-2 bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded focus:shadow-outline"
                                    >
                                        <Check strokeWidth="1.5" />
                                        Cancel job
                                    </button>
                                </fetcher.Form>
                            </div>
                        </div>
                    )}
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
}

export { CancelBtn, RetryBtn };
