import { Link } from 'react-router-dom';

const styles = {
    prevNext: {
        base: 'w-24 py-2 px-4 leading-tight rounded-md text-center',
        active: 'bg-blue-500 hover:bg-blue-600 text-white',
        inactive:
            'bg-white dark:bg-primary-dark-bg border-2 border-gray-300 dark:border-gray-100/20 rounded-lg text-gray-300 dark:text-gray-300/50 pointer-events-none',
    },
    page: {
        base: `py-2 px-4 leading-tight text-center hover:bg-gray-100 dark:hover:bg-gray-100/10 border-2 rounded-lg`,
        active: `border-blue-500`,
        inactive: `border-gray-300 dark:border-gray-100/20`,
    },
};

interface PaginationProps {
    currentPage: number;
    totalPages: number;
}

function PaginationColor({ currentPage, totalPages }: PaginationProps) {
    if (totalPages <= 1) {
        return;
    }

    function getPageURL(page: number) {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('page', page.toString());
        return `?${searchParams.toString()}`;
    }

    function renderPageNumbers() {
        let pages = [];

        if (currentPage === 1) {
            for (let i = 1; i <= Math.min(3, totalPages); i++) {
                pages.push(i);
            }
        } else if (currentPage === totalPages) {
            const start = Math.max(1, totalPages - 2);
            for (let i = start; i <= totalPages; i++) {
                pages.push(i);
            }
        } else {
            pages = [currentPage - 1, currentPage, currentPage + 1];
        }

        return pages.map((page) => (
            <Link
                key={page}
                to={getPageURL(page)}
                className={`${styles.page.base} ${
                    currentPage === page
                        ? styles.page.active
                        : styles.page.inactive
                }`}
            >
                {page}
            </Link>
        ));
    }

    return (
        <div className="grow flex gap-x-2 items-center justify-center text-sm">
            <Link
                to={getPageURL(currentPage - 1)}
                className={`${styles.prevNext.base} ${
                    currentPage === 1
                        ? styles.prevNext.inactive
                        : styles.prevNext.active
                }`}
                tabIndex={currentPage === 1 ? -1 : 0}
            >
                Previous
            </Link>
            {renderPageNumbers()}
            <Link
                to={getPageURL(currentPage + 1)}
                className={`${styles.prevNext.base} ${
                    currentPage === totalPages
                        ? styles.prevNext.inactive
                        : styles.prevNext.active
                }`}
                tabIndex={currentPage === totalPages ? -1 : 0}
            >
                Next
            </Link>
        </div>
    );
}

function PaginationGrayscale({
    currentPage,
    totalPages,
}: {
    currentPage: number;
    totalPages: number;
}) {
    if (totalPages <= 0) {
        return;
    }

    function getPageURL(page: number) {
        const urlSearchParams = new URLSearchParams(window.location.search);
        urlSearchParams.set('page', page.toString());
        return `?${urlSearchParams.toString()}`;
    }

    function renderPageLink(page: number) {
        return (
            <Link
                key={page}
                to={getPageURL(page)}
                className={`px-3 py-1 mx-1 rounded font-medium text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 ${
                    page === currentPage
                        ? 'ring-1 ring-gray-400 dark:ring-gray-500'
                        : ''
                }`}
            >
                {page}
            </Link>
        );
    }

    function renderEllipsis() {
        return (
            <span className="px-2 text-gray-600 dark:text-gray-300">...</span>
        );
    }

    function renderPageLinks() {
        if (totalPages <= 5) {
            return Array.from({ length: totalPages }, (_, i) => i + 1).map(
                renderPageLink
            );
        }

        if (currentPage <= 3) {
            return [
                ...Array.from({ length: 3 }, (_, i) => i + 1).map(
                    renderPageLink
                ),
                renderEllipsis(),
                renderPageLink(totalPages),
            ];
        }

        if (currentPage >= totalPages - 2) {
            return [
                renderPageLink(1),
                renderEllipsis(),
                ...Array.from({ length: 3 }, (_, i) => totalPages - 2 + i).map(
                    renderPageLink
                ),
            ];
        }

        return [
            renderPageLink(1),
            renderEllipsis(),
            ...Array.from({ length: 3 }, (_, i) => currentPage - 1 + i).map(
                renderPageLink
            ),
            renderEllipsis(),
            renderPageLink(totalPages),
        ];
    }

    return (
        <div className="flex items-center justify-center">
            <Link
                to={getPageURL(currentPage - 1)}
                className={`px-3 py-1 font-medium text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded ${
                    currentPage === 1 ? 'opacity-50 pointer-events-none' : ''
                }`}
            >
                ← Previous
            </Link>
            {renderPageLinks()}
            <Link
                to={getPageURL(currentPage + 1)}
                className={`px-3 py-1 font-medium text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded ${
                    currentPage === totalPages
                        ? 'opacity-50 pointer-events-none'
                        : ''
                }`}
            >
                Next →
            </Link>
        </div>
    );
}

/**
 * A simple pagination component.
 *
 * The 'grayscale' style shows the first and last pages; it also uses an ellipsis for more than 5 pages.
 * You can find this style on the remote cache and inference pages.
 *
 * The 'color' style only shows the two nearest page numbers.
 * You can find this style on the content and explore pages.
 */
function Pagination({
    style,
    currentPage,
    totalPages,
}: {
    style: 'color' | 'grayscale';
    currentPage: number;
    totalPages: number;
}) {
    if (style === 'color') {
        return (
            <PaginationColor
                currentPage={currentPage}
                totalPages={totalPages}
            />
        );
    } else if (style === 'grayscale') {
        return (
            <PaginationGrayscale
                currentPage={currentPage}
                totalPages={totalPages}
            />
        );
    }
}

export default Pagination;
