import { useState } from 'react';
import { Form, useActionData, useNavigate } from 'react-router-dom';
import ContentContainer from '../base/ContentContainer';

function SelectUsername() {
    const message = useActionData() as string;

    const lightInputStyles =
        'w-full bg-white focus:outline-none mt-1 border border-gray-400 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 block p-2.5';
    const darkInputStyles =
        'dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500';
    const inputStyles = `${lightInputStyles} ${darkInputStyles}`;

    return (
        <ContentContainer>
            <div className="w-full max-w-sm m-auto flex flex-col items-center items-stretch p-8 border-solid border-2 border-gray-300 dark:border-primary-dark-border rounded-lg text-sm shadow-md">
                <h1 className="text-2xl font-semibold mb-4 text-center">
                    Add username
                </h1>
                <p className="mb-4">Create a username to continue.</p>
                <div className="flex flex-col gap-4">
                    <Form method="post" className="flex flex-col gap-4">
                        <fieldset>
                            <label htmlFor="new-username" className="block">
                                Username
                                <br />
                                <span className="text-gray-700 dark:text-gray-400 text-xs">
                                    5-20 characters, letters and numbers only
                                </span>
                            </label>
                            <input
                                type="text"
                                id="new-username"
                                name="new_username"
                                required
                                className={inputStyles}
                            />
                        </fieldset>
                        <button
                            name="intent"
                            value="update_username"
                            className="self-stretch w-full bg-blue-400 text-white rounded-md p-2 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:ring focus:ring-blue-200 active:bg-blue-700"
                        >
                            Add username
                        </button>
                    </Form>
                    {message && (
                        <div className="bg-red-100 border border-red-400 px-4 py-3 rounded text-red-700 text-center">
                            {message}
                        </div>
                    )}
                </div>
            </div>
        </ContentContainer>
    );
}

export default SelectUsername;
