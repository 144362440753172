import ContentContainer from './ContentContainer';
import WebAppSidebar from './WebAppSidebar';

function WebAppContainer({ children }: { children: React.ReactNode }) {
    return (
        <div className="h-full w-full flex">
            <WebAppSidebar />
            <div className="h-full w-full flex flex-col grow items-stretch justify-start overflow-x-hidden">
                <ContentContainer>{children}</ContentContainer>
            </div>
        </div>
    );
}

export default WebAppContainer;
