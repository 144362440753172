export async function action({ request }) {
    const formData = await request.formData();
    let intent = formData.get('intent');

    let response: Response;

    if (intent === 'retry_job') {
        response = await fetch(`/api/home/remote-inference`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                job_uuid: formData.get('job_uuid'),
                status: 'queued',
            }),
        });
    } else if (intent === 'cancel_job') {
        response = await fetch(`/api/home/remote-inference`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                job_uuid: formData.get('job_uuid'),
                status: 'cancelling',
            }),
        });
    }

    if (response.ok && intent === 'retry_job') {
        return 'Success! Job added to queue.';
    } else if (response.ok && intent === 'cancel_job') {
        return 'Success! Cancellation request sent.';
    } else if (response.status === 422) {
        return 'Pydantic error.';
    } else if (response.status === 504) {
        return 'No response from server.';
    } else {
        const errorData = await response.json();
        return errorData.detail;
    }
}
