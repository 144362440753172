import { useLoaderData } from 'react-router-dom';
import ExceptionSummary from './ExceptionSummary';
import Interviews from './Interviews';
import Overview from './Overview';
import { TaskHistory } from './types';

interface ErrorData {
    task_history: TaskHistory;
}

function ErrorId() {
    const data = useLoaderData() as ErrorData;

    const taskHistory = data.task_history;

    return (
        <div className="space-y-6">
            <Overview taskHistory={taskHistory} />
            <ExceptionSummary taskHistory={taskHistory} />
            <Interviews taskHistory={taskHistory} />
        </div>
    );
}

export default ErrorId;
