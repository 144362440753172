import { useFetcher } from 'react-router-dom';
import ActionAlert from '../../../base/design-system/ActionAlert';

interface Demographics {
    age: number;
}

function DemographicForm({ demographics }: { demographics: Demographics }) {
    const fetcher = useFetcher();

    return (
        <fetcher.Form className="space-y-8" method="post">
            <div>
                <label
                    htmlFor="age"
                    className="block mb-2 text-base font-medium text-gray-900 dark:text-white"
                >
                    Age
                </label>
                <input
                    id="age"
                    name="age"
                    className="block p-2.5 w-64 text-sm bg-gray-50 focus:outline-none border rounded-lg border-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700/20 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:placeholder-gray-400"
                    defaultValue={
                        demographics.age === null
                            ? ''
                            : demographics.age.toString()
                    }
                />
            </div>
            <button
                type="submit"
                name="intent"
                value="update_demographics"
                className="self-end text-white px-8 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
                Update demographics
            </button>
            <ActionAlert response={fetcher.data} />
        </fetcher.Form>
    );
}

export default DemographicForm;
