import Agent from './Agent';
import AgentList from './AgentList';
import Cache from './Cache';
import Model from './Model';
import ModelList from './ModelList';
import Notebook from './Notebook';
import Question from './Question';
import Results from './Results';
import Scenario from './Scenario';
import ScenarioList from './ScenarioList';
import Study from './Study';
import Survey from './Survey';
import { EDSLObject } from '../../../../../types';

function ObjectData({ object }: { object: EDSLObject }) {
    if (object.object_type === 'agent') {
        return <Agent traits={object.data.traits} />;
    } else if (object.object_type === 'agent_list') {
        return (
            <AgentList
                columns={object.data.table.columns}
                records={object.data.table.records}
            />
        );
    } else if (object.object_type === 'cache') {
        return (
            <Cache
                columns={object.data.table.columns}
                records={object.data.table.records}
            />
        );
    } else if (object.object_type === 'model') {
        return (
            <Model
                params={{ model: object.data.model, ...object.data.parameters }}
            />
        );
    } else if (object.object_type === 'model_list') {
        return (
            <ModelList
                columns={object.data.table.columns}
                records={object.data.table.records}
            />
        );
    } else if (object.object_type === 'notebook') {
        return <Notebook notebookContent={object.data.notebook_content} />;
    } else if (object.object_type === 'question') {
        return (
            <Question
                questionOptions={object.data.question_options}
                questionText={object.data.question_text}
                questionType={object.data.question_type}
            />
        );
    } else if (object.object_type === 'results') {
        return <Results object={object} />;
    } else if (object.object_type === 'scenario') {
        return <Scenario entries={object.data} />;
    } else if (object.object_type === 'scenario_list') {
        return (
            <ScenarioList
                columns={object.data.table.columns}
                records={object.data.table.records}
            />
        );
    } else if (object.object_type === 'study') {
        return (
            <Study
                columns={object.data.table.columns}
                records={object.data.table.records}
            />
        );
    } else if (object.object_type === 'survey') {
        return <Survey survey={object} />;
    } else {
        return <div>Nothing to see here (yet!)</div>;
    }
}

export default ObjectData;
