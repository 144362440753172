import { TaskHistory } from './types';

function ExceptionSummary({ taskHistory }: { taskHistory: TaskHistory }) {
    const tableStyles = 'border-collapse border border-slate-300';
    const tableCellStyles = 'p-2 border border-slate-300';

    return (
        <div className="space-y-6">
            {/* Exceptions by Type */}
            <h2 className="text-xl font-bold">Exceptions by Type</h2>
            <table className={tableStyles}>
                <thead>
                    <tr>
                        <th className={tableCellStyles}>Exception Type</th>
                        <th className={tableCellStyles}>Number</th>
                    </tr>
                </thead>
                <tbody>
                    {taskHistory.exceptions_by_type.map((exception, index) => (
                        <tr key={index}>
                            <td className={tableCellStyles}>
                                {exception.exception_type}
                            </td>
                            <td className={tableCellStyles}>
                                {exception.num_exceptions}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {/* Exceptions by Model */}
            <h2 className="text-xl font-bold">Exceptions by Model</h2>
            <table className={tableStyles}>
                <thead>
                    <tr>
                        <th className={tableCellStyles}>Model</th>
                        <th className={tableCellStyles}>Number</th>
                    </tr>
                </thead>
                <tbody>
                    {taskHistory.exceptions_by_model.map((exception, index) => (
                        <tr key={index}>
                            <td className={tableCellStyles}>
                                ({exception.service}, {exception.model})
                            </td>
                            <td className={tableCellStyles}>
                                {exception.num_exceptions}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {/* Exceptions by Service */}
            <h2 className="text-xl font-bold">Exceptions by Service</h2>
            <table className={tableStyles}>
                <thead>
                    <tr>
                        <th className={tableCellStyles}>Service</th>
                        <th className={tableCellStyles}>Number</th>
                    </tr>
                </thead>
                <tbody>
                    {taskHistory.exceptions_by_service.map(
                        (exception, index) => (
                            <tr key={index}>
                                <td className={tableCellStyles}>
                                    {exception.service}
                                </td>
                                <td className={tableCellStyles}>
                                    {exception.num_exceptions}
                                </td>
                            </tr>
                        )
                    )}
                </tbody>
            </table>
            {/* Exceptions by Question Name */}
            <h2 className="text-xl font-bold">Exceptions by Question Name</h2>
            <table className={tableStyles}>
                <thead>
                    <tr>
                        <th className={tableCellStyles}>Question</th>
                        <th className={tableCellStyles}>Number</th>
                    </tr>
                </thead>
                <tbody>
                    {taskHistory.exceptions_by_question_name.map(
                        (exception, index) => (
                            <tr key={index}>
                                <td className={tableCellStyles}>
                                    ({exception.question_name},{' '}
                                    {exception.question_type})
                                </td>
                                <td className={tableCellStyles}>
                                    {exception.num_exceptions}
                                </td>
                            </tr>
                        )
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default ExceptionSummary;
