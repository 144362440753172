import { useFetcher } from 'react-router-dom';

function DeleteUnverifiedUsersForm() {
    const fetcher = useFetcher();

    return (
        <fetcher.Form method="post">
            <button
                name="intent"
                value="delete_unverified_users"
                className="flex gap-2 px-6 py-2.5 bg-green-700 hover:bg-green-800 hover:transition-colors rounded-md text-white font-medium"
            >
                Delete unverified users
            </button>
        </fetcher.Form>
    );
}

export default DeleteUnverifiedUsersForm;
