import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import About from './components/About';
import Admin, {
    AdminConfigVars,
    adminConfigVarsLoader,
    adminConfigVarsAction,
    AdminLogin,
    adminLoginLoader,
    AdminEDSLVersion,
    adminEDSLVersionLoader,
    AdminObjects,
    adminObjectsLoader,
    adminObjectsAction,
    AdminStaleObjects,
    adminStaleObjectsAction,
    AdminUsers,
    adminUsersLoader,
    adminUsersAction,
    AdminUserStatistics,
    adminUserStatisticsLoader,
} from './components/Admin';
import App, { AppIndex, GlobalErrorPage } from './components/App';
import Content, {
    ContentId,
    contentIdAction,
    contentIdLoader,
    ContentIndex,
    contentIndexAction,
    contentIndexLoader,
    Explore,
    exploreAction,
    exploreLoader,
    Favorites,
    favoritesAction,
    favoritesLoader,
} from './components/Content';
import CreateIndex, {
    Survey as CreateSurvey,
    surveyAction as createSurveyAction,
    Project as CreateProject,
    projectAction as createProjectAction,
} from './components/Create';
import { editSurveyLoader, editSurveyAction } from './components/Edit';
import ErrorPage from './components/base/ErrorPage';
import ForgotPassword, {
    action as forgotPasswordAction,
} from './components/ForgotPassword';
import GettingStarted, {
    GettingStartedIndex,
    EDSLInstallation,
    EDSLAPIKeys,
    CoopLoggingIn,
    CoopObjects,
    CoopRemoteCache,
    CoopRemoteInference,
    CoopPricing,
    coopPricingLoader,
} from './components/GettingStarted';
import Home, {
    HomeIndex,
    loader as homeLoader,
    API,
    APIAction,
    APILoader,
    Profile,
    profileAction,
    profileLoader,
    Projects,
    projectsAction,
    projectsLoader,
    ProjectDetails,
    projectDetailsLoader,
    projectDetailsAction,
    Purchases,
    purchasesLoader,
    purchasesAction,
    RemoteCache,
    remoteCacheLoader,
    RemoteCacheEntries,
    remoteCacheEntriesLoader,
    remoteCacheEntriesAction,
    RemoteInference,
    remoteInferenceLoader,
    remoteInferenceAction,
    RemoteInferenceError,
    remoteInferenceErrorLoader,
    Respond,
    respondLoader,
    respondAction,
} from './components/Home';
import Login, {
    action as loginAction,
    loader as loginLoader,
} from './components/Login';
import Message from './components/base/Message';
import Privacy from './components/Privacy';
import ResetPassword, {
    action as resetPasswordAction,
} from './components/ResetPassword';
import SelectUsername, {
    action as selectUsernameAction,
} from './components/SelectUsername';
import { Survey, surveyLoader, surveyAction } from './components/Respond';
import Terms from './components/Terms';
import {
    PublicProfile,
    publicProfileLoader,
    publicProfileAction,
} from './components/Users';
import VerifyEmail, {
    loader as verifyEmailLoader,
} from './components/VerifyEmail';
import WebAppContainer from './components/base/WebAppContainer';
import {
    adminAuthCheck,
    betaAuthCheck,
    softAuthCheck,
} from './helpers/auth.helpers';

const router = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        errorElement: <GlobalErrorPage />,
        children: [
            {
                index: true,
                element: <AppIndex />,
                loader: softAuthCheck,
            },
            {
                path: '/about',
                element: <About />,
                loader: softAuthCheck,
            },
            {
                element: <Admin />,
                loader: adminAuthCheck,
                children: [
                    {
                        path: '/admin/config-vars',
                        element: <AdminConfigVars />,
                        loader: adminConfigVarsLoader,
                        action: adminConfigVarsAction,
                    },
                    {
                        path: '/admin/edsl-version',
                        element: <AdminEDSLVersion />,
                        loader: adminEDSLVersionLoader,
                    },
                    {
                        path: '/admin/users',
                        element: <AdminUsers />,
                        loader: adminUsersLoader,
                        action: adminUsersAction,
                    },
                    {
                        path: '/admin/users/:username',
                        element: <PublicProfile />,
                        loader: publicProfileLoader,
                        action: publicProfileAction,
                    },
                    {
                        path: '/admin/objects',
                        element: <AdminObjects />,
                        loader: adminObjectsLoader,
                        action: adminObjectsAction,
                    },
                    {
                        path: '/admin/objects/:contentId',
                        element: <ContentId />,
                        loader: contentIdLoader,
                        action: contentIdAction,
                    },
                    {
                        path: '/admin/remote-cache',
                        element: <RemoteCache />,
                        loader: remoteCacheLoader,
                    },
                    {
                        path: '/admin/remote-cache/entries',
                        element: <RemoteCacheEntries />,
                        loader: remoteCacheEntriesLoader,
                        action: remoteCacheEntriesAction,
                    },
                    {
                        path: '/admin/remote-inference',
                        element: <RemoteInference />,
                        loader: remoteInferenceLoader,
                        action: remoteInferenceAction,
                    },
                    {
                        path: '/admin/remote-inference/error/:errorId',
                        element: <RemoteInferenceError />,
                        loader: remoteInferenceErrorLoader,
                    },
                    {
                        path: '/admin/stale-objects',
                        element: <AdminStaleObjects />,
                        action: adminStaleObjectsAction,
                    },
                    {
                        path: '/admin/user-statistics',
                        element: <AdminUserStatistics />,
                        loader: adminUserStatisticsLoader,
                    },
                ],
            },
            {
                path: '/admin/login',
                element: <AdminLogin />,
                loader: adminLoginLoader,
            },
            {
                path: '/checkout-cancel',
                element: (
                    <Message
                        title="Checkout canceled ❌"
                        mainMessage="Flying back to your purchases page...🦜"
                        redirectUrl="/home/purchases"
                    />
                ),
            },
            {
                path: '/checkout-success',
                element: (
                    <Message
                        title="Checkout success ✅"
                        mainMessage="Flying back to your purchases page...🦜"
                        redirectUrl="/home/purchases"
                    />
                ),
            },
            {
                path: '/content',
                element: <Content />,
                errorElement: <ErrorPage />,
                children: [
                    {
                        index: true,
                        element: <ContentIndex />,
                        loader: contentIndexLoader,
                        action: contentIndexAction,
                    },
                    {
                        path: '/content/:contentId',
                        element: <ContentId />,
                        loader: contentIdLoader,
                        action: contentIdAction,
                    },
                    {
                        path: '/content/explore',
                        element: <Explore />,
                        loader: exploreLoader,
                        action: exploreAction,
                    },
                    {
                        path: '/content/favorites',
                        element: <Favorites />,
                        loader: favoritesLoader,
                        action: favoritesAction,
                    },
                ],
            },
            {
                path: '/create',
                element: (
                    <WebAppContainer>
                        <CreateIndex />
                    </WebAppContainer>
                ),
                loader: betaAuthCheck,
            },
            {
                path: '/create/project',
                element: (
                    <WebAppContainer>
                        <CreateProject />
                    </WebAppContainer>
                ),
                loader: betaAuthCheck,
                action: createProjectAction,
            },
            {
                path: '/create/survey',
                element: (
                    <WebAppContainer>
                        <CreateSurvey />
                    </WebAppContainer>
                ),
                loader: betaAuthCheck,
                action: createSurveyAction,
            },
            {
                path: '/edit/survey/:surveyId',
                element: (
                    <WebAppContainer>
                        <CreateSurvey />
                    </WebAppContainer>
                ),
                loader: editSurveyLoader,
                action: editSurveyAction,
            },
            {
                path: '/end-of-survey',
                element: (
                    <Message
                        title="Thank you for completing this survey! 🦜"
                        mainMessage="Your response has been recorded."
                        redirectUrl="/"
                    />
                ),
            },
            {
                path: '/forgot-password',
                element: <ForgotPassword />,
                loader: softAuthCheck,
                action: forgotPasswordAction,
            },
            {
                path: '/getting-started',
                element: <GettingStarted />,
                loader: softAuthCheck,
                children: [
                    {
                        index: true,
                        element: <GettingStartedIndex />,
                    },
                    {
                        path: '/getting-started/edsl-installation',
                        element: <EDSLInstallation />,
                    },
                    {
                        path: '/getting-started/edsl-api-keys',
                        element: <EDSLAPIKeys />,
                    },
                    {
                        path: '/getting-started/coop-logging-in',
                        element: <CoopLoggingIn />,
                    },
                    {
                        path: '/getting-started/coop-how-it-works',
                        element: <CoopObjects />,
                    },
                    {
                        path: '/getting-started/coop-remote-cache',
                        element: <CoopRemoteCache />,
                    },
                    {
                        path: '/getting-started/coop-remote-inference',
                        element: <CoopRemoteInference />,
                    },
                    {
                        path: '/getting-started/coop-pricing',
                        element: <CoopPricing />,
                        loader: coopPricingLoader,
                    },
                ],
            },
            {
                path: '/goodbye',
                element: (
                    <Message
                        title="Your account was deleted successfully"
                        mainMessage="We're sad to see you go 😔 But we will forever be expecting you to come back! 🦜"
                        redirectUrl="/"
                    />
                ),
            },
            {
                path: '/home',
                element: <Home />,
                errorElement: <ErrorPage />,
                children: [
                    {
                        index: true,
                        element: <HomeIndex />,
                        loader: homeLoader,
                    },
                    {
                        path: '/home/api',
                        element: <API />,
                        loader: APILoader,
                        action: APIAction,
                    },
                    {
                        path: '/home/pricing',
                        element: <CoopPricing />,
                        loader: coopPricingLoader,
                    },
                    {
                        path: '/home/projects',
                        element: <Projects />,
                        loader: projectsLoader,
                        action: projectsAction,
                    },
                    {
                        path: '/home/projects/:projectId',
                        element: <ProjectDetails />,
                        loader: projectDetailsLoader,
                        action: projectDetailsAction,
                    },
                    {
                        path: '/home/purchases',
                        element: <Purchases />,
                        loader: purchasesLoader,
                        action: purchasesAction,
                    },
                    {
                        path: '/home/profile',
                        element: <Profile />,
                        loader: profileLoader,
                        action: profileAction,
                    },
                    {
                        path: '/home/remote-cache',
                        element: <RemoteCache />,
                        loader: remoteCacheLoader,
                    },
                    {
                        path: '/home/remote-cache/entries',
                        element: <RemoteCacheEntries />,
                        loader: remoteCacheEntriesLoader,
                        action: remoteCacheEntriesAction,
                    },
                    {
                        path: '/home/remote-inference',
                        element: <RemoteInference />,
                        loader: remoteInferenceLoader,
                        action: remoteInferenceAction,
                    },
                    {
                        path: '/home/remote-inference/error/:errorId',
                        element: <RemoteInferenceError />,
                        loader: remoteInferenceErrorLoader,
                    },
                    {
                        path: '/home/respond',
                        element: <Respond />,
                        loader: respondLoader,
                        action: respondAction,
                    },
                ],
            },
            {
                path: '/login',
                element: <Login />,
                loader: loginLoader,
                action: loginAction,
            },
            {
                path: '/privacy',
                element: <Privacy />,
                loader: softAuthCheck,
            },
            {
                path: '/reset-password',
                element: <ResetPassword />,
                action: resetPasswordAction,
            },
            {
                path: '/respond/:projectId',
                element: <Survey />,
                loader: surveyLoader,
                action: surveyAction,
            },
            {
                path: '/select-username',
                element: <SelectUsername />,
                action: selectUsernameAction,
            },
            {
                path: '/terms',
                element: <Terms />,
                loader: softAuthCheck,
            },
            {
                path: '/token-auth-success',
                element: (
                    <Message
                        title="Login successful ✅"
                        mainMessage="You can close this page and go back to your code"
                        redirectUrl="/home"
                    />
                ),
            },
            {
                path: '/users/:username',
                element: (
                    <WebAppContainer>
                        <PublicProfile />
                    </WebAppContainer>
                ),
                loader: publicProfileLoader,
                action: publicProfileAction,
            },
            {
                path: '/verify-email',
                element: <VerifyEmail />,
                loader: verifyEmailLoader,
            },
        ],
    },
]);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
