import { Feather } from 'react-feather';

type CalloutStyle = 'info' | 'caution';

interface CalloutProps {
    title: string;
    children: React.ReactNode;
    style?: CalloutStyle;
}

function Callout({ title, children, style = 'info' }: CalloutProps) {
    if (style === 'caution') {
        return (
            <div className="border-l-4 border-red-500 dark:border-red-800">
                <div className="ps-3 flex items-center space-x-2 py-1 bg-red-100 dark:bg-red-800">
                    <Feather className="w-4 h-4" strokeWidth="1.5" />
                    <span>{title}</span>
                </div>
                <div className="ps-3 py-1">{children}</div>
            </div>
        );
    }
    return (
        <div className="border-l-4 border-green-700 dark:border-blue-900">
            <div className="ps-3 flex items-center space-x-2 py-1 bg-green-100 dark:bg-blue-900">
                <Feather className="w-4 h-4" strokeWidth="1.5" />
                <span>{title}</span>
            </div>
            <div className="ps-3 py-1">{children}</div>
        </div>
    );
}

export default Callout;
