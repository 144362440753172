import { useLoaderData, Link } from 'react-router-dom';
import Pagination from '../../../base/Pagination';
import { formatDate } from '../../../../utils';

interface RemoteCacheData {
    total_cache_entries: number;
    cache_logs: {
        created_ts: number;
        description: string;
        cache_entry_count: number;
    }[];
    cache_logs_count: number;
    current_page: number;
    page_size: number;
    total_pages: number;
    is_admin: boolean;
}

function RemoteCache() {
    const data = useLoaderData() as RemoteCacheData;

    const startIndex = (data.current_page - 1) * data.page_size + 1;
    const endIndex = Math.min(
        startIndex + data.page_size - 1,
        data.cache_logs_count
    );

    const cacheEntriesLink = data.is_admin
        ? '/admin/remote-cache/entries'
        : '/home/remote-cache/entries';

    return (
        <>
            <div className="space-y-2">
                <h1 className="font-bold text-3xl pb-2">Remote Cache</h1>
                <p className="text-lg pb-2">
                    Manage your remote cache entries.
                </p>
                <div className="text-center">
                    {data.total_cache_entries > 0 ? (
                        <>
                            <p className="text-2xl">
                                {data.total_cache_entries} entries in remote
                                cache
                            </p>
                            <Link
                                to={cacheEntriesLink}
                                className="text-lg pb-2 text-blue-500 hover:text-blue-600 dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent"
                            >
                                View entries
                            </Link>
                        </>
                    ) : (
                        <>
                            <p className="text-2xl">
                                You don't have any remote cache entries 😔
                            </p>
                            <p className="text-2xl">
                                How about{' '}
                                <Link
                                    to="/home/api"
                                    className="text-blue-500 hover:text-blue-600 dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent"
                                >
                                    activating remote caching
                                </Link>
                                ?
                            </p>
                        </>
                    )}
                </div>
            </div>
            <div className="space-y-4 mt-8">
                <h2 className="font-bold text-2xl">History</h2>
                {data.cache_logs_count === 0 ? (
                    <p>Showing 0 logs</p>
                ) : (
                    <p>
                        Showing {startIndex}-{endIndex} of{' '}
                        {data.cache_logs_count} logs
                    </p>
                )}
                <Pagination
                    style="grayscale"
                    currentPage={data.current_page}
                    totalPages={data.total_pages}
                />
                <table className="w-full table-auto bg-white dark:bg-gray-800 shadow-md rounded-lg overflow-hidden text-center">
                    <thead className="bg-gray-100 dark:bg-gray-700 border-gray-200 dark:border-gray-600 border-b-2">
                        <tr>
                            <th className="px-4 py-2 font-bold text-blue-500 dark:text-primary-dark-text-accent">
                                Date
                            </th>
                            <th className="px-4 py-2 font-bold text-blue-500 dark:text-primary-dark-text-accent">
                                Description
                            </th>
                            <th className="px-4 py-2 font-bold text-blue-500 dark:text-primary-dark-text-accent">
                                Number of Cache Entries
                            </th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 dark:divide-gray-600">
                        {data.cache_logs.map((log, index) => (
                            <tr
                                key={index}
                                className={
                                    index % 2 === 0
                                        ? 'bg-white dark:bg-gray-800'
                                        : 'bg-gray-50 dark:bg-gray-700'
                                }
                            >
                                <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">
                                    {formatDate(log.created_ts, 'MMMM D, YYYY')}
                                </td>
                                <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">
                                    {log.description}
                                </td>
                                <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">
                                    {log.cache_entry_count}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default RemoteCache;
