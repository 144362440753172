import { Circle, Square, X } from 'react-feather';
import {
    QuestionWithOptions,
    QuestionType,
    QuestionProps,
    QuestionBudgetProps,
    QuestionCheckBoxProps,
    QuestionLikertFiveProps,
    QuestionLinearScaleProps,
    QuestionListProps,
    QuestionMultipleChoiceProps,
    QuestionNumericalProps,
    QuestionRankProps,
    QuestionTopKProps,
    QuestionYesNoProps,
} from './types';

function OptionIcon({ questionType }: { questionType: QuestionType }) {
    switch (questionType) {
        case 'checkbox':
            return <Square className="w-6 text-gray-400" />;
        case 'linear_scale':
        case 'multiple_choice':
        case 'yes_no':
            return <Circle className="w-6 text-gray-400" />;
        default:
            return null;
    }
}

function Options({
    question,
    addOption,
    handleOptionTextChange,
    removeOption,
}: {
    question: QuestionWithOptions;
    addOption: (question: QuestionWithOptions) => void;
    handleOptionTextChange: (
        question: QuestionWithOptions,
        optionId: string,
        optionText: string
    ) => void;
    removeOption: (question: QuestionWithOptions, optionId: string) => void;
}) {
    const currentOptionsCount = question.structure.options.length;
    const minNumOptions = question.constraints.minNumOptions;
    const maxNumOptions = question.constraints.maxNumOptions;

    return (
        <div className="space-y-8">
            <div className="space-y-3">
                {question.structure.options.map((option) => (
                    <div key={option.id} className="flex items-center gap-x-4">
                        <OptionIcon questionType={question.type} />
                        <input
                            type="text"
                            value={option.text}
                            onChange={(event) =>
                                handleOptionTextChange(
                                    question,
                                    option.id,
                                    event.target.value
                                )
                            }
                            className="grow bg-transparent focus:outline-none border-b-2 border-gray-200 focus:border-b-2 focus:border-b-blue-500 block p-2.5 dark:border-b-gray-500 dark:focus:border-b-blue-500"
                        />
                        {currentOptionsCount > minNumOptions && (
                            <button
                                onClick={() =>
                                    removeOption(question, option.id)
                                }
                                className="px-2.5 py-2.5 hover:bg-gray-300/20 transition-colors rounded-md text-gray-500"
                                aria-label="Remove option"
                            >
                                <X />
                            </button>
                        )}
                    </div>
                ))}
            </div>
            {currentOptionsCount < maxNumOptions && (
                <button
                    onClick={() => addOption(question)}
                    className="px-4 py-2.5 hover:bg-gray-300/20 transition-colors border border-gray-300 dark:border-2 dark:border-gray-500 rounded-md font-medium"
                >
                    Add option
                </button>
            )}
        </div>
    );
}

function QuestionBudget({
    question,
    updateQuestion,
    addOption,
    handleOptionTextChange,
    removeOption,
}: QuestionBudgetProps) {
    const numSelections = question.structure.budgetSum;

    return (
        <>
            <div className="flex gap-x-8">
                <label
                    className="text-blue-500 dark:text-primary-dark-text-accent font-bold py-2"
                    htmlFor={`${question.id}-budgetSum`}
                >
                    Budget sum
                </label>
                <input
                    type="text"
                    id={`${question.id}-budgetSum`}
                    name={`${question.id}-budgetSum`}
                    value={numSelections}
                    placeholder="Number"
                    onChange={(event) => {
                        updateQuestion({
                            ...question,
                            structure: {
                                ...question.structure,
                                budgetSum: event.target.value,
                            },
                        });
                    }}
                    className="max-w-56 grow bg-transparent focus:outline-none border-b-2 border-gray-200 focus:border-b-2 focus:border-b-blue-500 block py-2.5 dark:border-b-gray-500 dark:focus:border-b-blue-500 dark:placeholder-gray-400"
                />
            </div>
            <Options
                question={question}
                addOption={addOption}
                handleOptionTextChange={handleOptionTextChange}
                removeOption={removeOption}
            />
        </>
    );
}

function QuestionCheckBox({
    question,
    updateQuestion,
    addOption,
    handleOptionTextChange,
    removeOption,
}: QuestionCheckBoxProps) {
    const minSelections = question.structure.minSelections;
    const maxSelections = question.structure.maxSelections;
    return (
        <>
            <div className="flex flex-col md:flex-row md:flex-wrap gap-8">
                <div className="flex gap-x-8">
                    <label
                        className="block text-blue-500 dark:text-primary-dark-text-accent font-bold py-2"
                        htmlFor={`${question.id}-minSelections`}
                    >
                        Select at least...
                    </label>
                    <input
                        type="text"
                        id={`${question.id}-minSelections`}
                        name={`${question.id}-minSelections`}
                        value={minSelections}
                        placeholder="Number"
                        onChange={(event) => {
                            updateQuestion({
                                ...question,
                                structure: {
                                    ...question.structure,
                                    minSelections: event.target.value,
                                },
                            });
                        }}
                        className="max-w-56 grow bg-transparent focus:outline-none border-b-2 border-gray-200 focus:border-b-2 focus:border-b-blue-500 block py-2.5 dark:border-b-gray-500 dark:focus:border-b-blue-500 dark:placeholder-gray-400"
                    />
                </div>
                <div className="flex gap-x-8">
                    <label
                        className="block text-blue-500 dark:text-primary-dark-text-accent font-bold py-2"
                        htmlFor={`${question.id}-maxSelections`}
                    >
                        Select at most...
                    </label>
                    <input
                        type="text"
                        id={`${question.id}-maxSelections`}
                        name={`${question.id}-maxSelections`}
                        placeholder="Number"
                        value={maxSelections}
                        onChange={(event) => {
                            updateQuestion({
                                ...question,
                                structure: {
                                    ...question.structure,
                                    maxSelections: event.target.value,
                                },
                            });
                        }}
                        className="max-w-56 grow bg-transparent focus:outline-none border-b-2 border-gray-200 focus:border-b-2 focus:border-b-blue-500 block py-2.5 dark:border-b-gray-500 dark:focus:border-b-blue-500 dark:placeholder-gray-400"
                    />
                </div>
            </div>
            <Options
                question={question}
                addOption={addOption}
                handleOptionTextChange={handleOptionTextChange}
                removeOption={removeOption}
            />
        </>
    );
}

function QuestionFreeText() {
    return null;
}

function QuestionLikertFive({
    question,
    addOption,
    handleOptionTextChange,
    removeOption,
}: QuestionLikertFiveProps) {
    return (
        <>
            <Options
                question={question}
                addOption={addOption}
                handleOptionTextChange={handleOptionTextChange}
                removeOption={removeOption}
            />
        </>
    );
}

function QuestionLinearScale({
    question,
    addOption,
    handleOptionLabelChange,
    handleOptionTextChange,
    removeOption,
}: QuestionLinearScaleProps) {
    const currentOptionsCount = question.structure.options.length;
    const minNumOptions = question.constraints.minNumOptions;
    const maxNumOptions = question.constraints.maxNumOptions;

    return (
        <>
            <div className="space-y-8">
                <div className="space-y-3">
                    {question.structure.options.map((option) => (
                        <div
                            key={option.id}
                            className="flex items-center gap-x-4"
                        >
                            <OptionIcon questionType={question.type} />
                            <input
                                type="text"
                                placeholder="Number"
                                value={option.text}
                                onChange={(event) => {
                                    handleOptionTextChange(
                                        question,
                                        option.id,
                                        event.target.value
                                    );
                                }}
                                className="min-w-0 shrink max-w-20 sm:max-w-36 bg-transparent focus:outline-none border-b-2 border-gray-200 focus:border-b-2 focus:border-b-blue-500 block py-2.5 dark:border-b-gray-500 dark:focus:border-b-blue-500 dark:placeholder-gray-400"
                            />
                            <input
                                type="text"
                                placeholder="Label (Optional)"
                                value={option.label}
                                onChange={(event) =>
                                    handleOptionLabelChange(
                                        question,
                                        option.id,
                                        event.target.value
                                    )
                                }
                                className="min-w-0 grow bg-transparent focus:outline-none border-b-2 border-gray-200 focus:border-b-2 focus:border-b-blue-500 block p-2.5 dark:border-b-gray-500 dark:focus:border-b-blue-500 dark:placeholder-gray-400"
                            />
                            {currentOptionsCount > minNumOptions && (
                                <button
                                    onClick={() =>
                                        removeOption(question, option.id)
                                    }
                                    className="px-2.5 py-2.5 hover:bg-gray-300/20 transition-colors rounded-md text-gray-500"
                                    aria-label="Remove option"
                                >
                                    <X />
                                </button>
                            )}
                        </div>
                    ))}
                </div>
                {currentOptionsCount < maxNumOptions && (
                    <button
                        onClick={() => addOption(question)}
                        className="px-4 py-2.5 hover:bg-gray-300/20 transition-colors border border-gray-300 dark:border-2 dark:border-gray-500 rounded-md font-medium"
                    >
                        Add option
                    </button>
                )}
            </div>
        </>
    );
}

function QuestionList({ question, updateQuestion }: QuestionListProps) {
    const maxListItems = question.structure.maxListItems;
    return (
        <>
            <div className="flex gap-x-8">
                <label
                    className="block text-blue-500 dark:text-primary-dark-text-accent font-bold py-2"
                    htmlFor={`${question.id}-maxListItems`}
                >
                    Max list items
                </label>
                <input
                    type="text"
                    id={`${question.id}-maxListItems`}
                    name={`${question.id}-maxListItems`}
                    value={maxListItems}
                    placeholder="Number"
                    onChange={(event) => {
                        updateQuestion({
                            ...question,
                            structure: {
                                ...question.structure,
                                maxListItems: event.target.value,
                            },
                        });
                    }}
                    className="max-w-56 grow bg-transparent focus:outline-none border-b-2 border-gray-200 focus:border-b-2 focus:border-b-blue-500 block py-2.5 dark:border-b-gray-500 dark:focus:border-b-blue-500 dark:placeholder-gray-400"
                />
            </div>
        </>
    );
}

function QuestionMultipleChoice({
    question,
    addOption,
    handleOptionTextChange,
    removeOption,
}: QuestionMultipleChoiceProps) {
    return (
        <>
            <Options
                question={question}
                addOption={addOption}
                handleOptionTextChange={handleOptionTextChange}
                removeOption={removeOption}
            />
        </>
    );
}

function QuestionNumerical({
    question,
    updateQuestion,
}: QuestionNumericalProps) {
    const minValue = question.structure.minValue;
    const maxValue = question.structure.maxValue;
    return (
        <>
            <div className="flex flex-col md:flex-row md:flex-wrap gap-8">
                <div className="flex gap-x-8">
                    <label
                        className="block text-blue-500 dark:text-primary-dark-text-accent font-bold py-2"
                        htmlFor={`${question.id}-minValue`}
                    >
                        Minimum
                    </label>
                    <input
                        type="text"
                        id={`${question.id}-minValue`}
                        name={`${question.id}-minValue`}
                        value={minValue}
                        placeholder="Number"
                        onChange={(event) => {
                            updateQuestion({
                                ...question,
                                structure: {
                                    ...question.structure,
                                    minValue: event.target.value,
                                },
                            });
                        }}
                        className="max-w-56 grow bg-transparent focus:outline-none border-b-2 border-gray-200 focus:border-b-2 focus:border-b-blue-500 block py-2.5 dark:border-b-gray-500 dark:focus:border-b-blue-500 dark:placeholder-gray-400"
                    />
                </div>
                <div className="flex gap-x-8">
                    <label
                        className="block text-blue-500 dark:text-primary-dark-text-accent font-bold py-2"
                        htmlFor={`${question.id}-maxValue`}
                    >
                        Maximum
                    </label>
                    <input
                        type="text"
                        id={`${question.id}-maxValue`}
                        name={`${question.id}-maxValue`}
                        placeholder="Number"
                        value={maxValue}
                        onChange={(event) => {
                            updateQuestion({
                                ...question,
                                structure: {
                                    ...question.structure,
                                    maxValue: event.target.value,
                                },
                            });
                        }}
                        className="max-w-56 grow bg-transparent focus:outline-none border-b-2 border-gray-200 focus:border-b-2 focus:border-b-blue-500 block py-2.5 dark:border-b-gray-500 dark:focus:border-b-blue-500 dark:placeholder-gray-400"
                    />
                </div>
            </div>
        </>
    );
}

function QuestionRank({
    question,
    updateQuestion,
    addOption,
    handleOptionTextChange,
    removeOption,
}: QuestionRankProps) {
    const numSelections = question.structure.numSelections;
    return (
        <>
            <div className="flex gap-x-8">
                <label
                    className="block text-blue-500 dark:text-primary-dark-text-accent font-bold py-2"
                    htmlFor={`${question.id}-numSelections`}
                >
                    Selections
                </label>
                <input
                    type="text"
                    id={`${question.id}-numSelections`}
                    name={`${question.id}-numSelections`}
                    value={numSelections}
                    placeholder="Number"
                    onChange={(event) => {
                        updateQuestion({
                            ...question,
                            structure: {
                                ...question.structure,
                                numSelections: event.target.value,
                            },
                        });
                    }}
                    className="max-w-56 grow bg-transparent focus:outline-none border-b-2 border-gray-200 focus:border-b-2 focus:border-b-blue-500 block py-2.5 dark:border-b-gray-500 dark:focus:border-b-blue-500 dark:placeholder-gray-400"
                />
            </div>
            <Options
                question={question}
                addOption={addOption}
                handleOptionTextChange={handleOptionTextChange}
                removeOption={removeOption}
            />
        </>
    );
}

function QuestionTopK({
    question,
    updateQuestion,
    addOption,
    handleOptionTextChange,
    removeOption,
}: QuestionTopKProps) {
    // Min and max are the same for TopK
    const numSelections = question.structure.minSelections;
    return (
        <>
            <div className="flex gap-x-8">
                <label
                    className="block text-blue-500 dark:text-primary-dark-text-accent font-bold py-2"
                    htmlFor={`${question.id}-numSelections`}
                >
                    Select exactly...
                </label>
                <input
                    type="text"
                    id={`${question.id}-numSelections`}
                    name={`${question.id}-numSelections`}
                    value={numSelections}
                    placeholder="Number"
                    onChange={(event) => {
                        updateQuestion({
                            ...question,
                            structure: {
                                ...question.structure,
                                minSelections: event.target.value,
                                maxSelections: event.target.value,
                            },
                        });
                    }}
                    className="max-w-56 grow bg-transparent focus:outline-none border-b-2 border-gray-200 focus:border-b-2 focus:border-b-blue-500 block py-2.5 dark:border-b-gray-500 dark:focus:border-b-blue-500 dark:placeholder-gray-400"
                />
            </div>
            <Options
                question={question}
                addOption={addOption}
                handleOptionTextChange={handleOptionTextChange}
                removeOption={removeOption}
            />
        </>
    );
}

function QuestionYesNo({
    question,
    addOption,
    handleOptionTextChange,
    removeOption,
}: QuestionYesNoProps) {
    return (
        <>
            <Options
                question={question}
                addOption={addOption}
                handleOptionTextChange={handleOptionTextChange}
                removeOption={removeOption}
            />
        </>
    );
}

function Question({
    question,
    updateQuestion,
    addOption,
    handleOptionLabelChange,
    handleOptionTextChange,
    removeOption,
}: QuestionProps) {
    switch (question.type) {
        case 'budget':
            return (
                <QuestionBudget
                    question={question}
                    updateQuestion={updateQuestion}
                    addOption={addOption}
                    handleOptionTextChange={handleOptionTextChange}
                    removeOption={removeOption}
                />
            );
        case 'checkbox':
            return (
                <QuestionCheckBox
                    question={question}
                    updateQuestion={updateQuestion}
                    addOption={addOption}
                    handleOptionTextChange={handleOptionTextChange}
                    removeOption={removeOption}
                />
            );
        case 'free_text':
            return <QuestionFreeText />;
        case 'likert_five':
            return (
                <QuestionLikertFive
                    question={question}
                    addOption={addOption}
                    handleOptionTextChange={handleOptionTextChange}
                    removeOption={removeOption}
                />
            );
        case 'linear_scale':
            return (
                <QuestionLinearScale
                    question={question}
                    addOption={addOption}
                    handleOptionLabelChange={handleOptionLabelChange}
                    handleOptionTextChange={handleOptionTextChange}
                    removeOption={removeOption}
                />
            );
        case 'list':
            return (
                <QuestionList
                    question={question}
                    updateQuestion={updateQuestion}
                />
            );
        case 'multiple_choice':
            return (
                <QuestionMultipleChoice
                    question={question}
                    addOption={addOption}
                    handleOptionTextChange={handleOptionTextChange}
                    removeOption={removeOption}
                />
            );
        case 'numerical':
            return (
                <QuestionNumerical
                    question={question}
                    updateQuestion={updateQuestion}
                />
            );
        case 'rank':
            return (
                <QuestionRank
                    question={question}
                    updateQuestion={updateQuestion}
                    addOption={addOption}
                    handleOptionTextChange={handleOptionTextChange}
                    removeOption={removeOption}
                />
            );
        case 'top_k':
            return (
                <QuestionTopK
                    question={question}
                    updateQuestion={updateQuestion}
                    addOption={addOption}
                    handleOptionTextChange={handleOptionTextChange}
                    removeOption={removeOption}
                />
            );
        case 'yes_no':
            return (
                <QuestionYesNo
                    question={question}
                    addOption={addOption}
                    handleOptionTextChange={handleOptionTextChange}
                    removeOption={removeOption}
                />
            );
        default:
            return "We don't have a component for this question type yet!";
    }
}

export default Question;
