const chipArray = [
    {
        objectType: 'agent',
        color: 'bg-pink-200',
        label: 'Agent',
    },
    {
        objectType: 'agent_list',
        color: 'bg-pink-300',
        label: '[ Agent ]',
    },
    {
        objectType: 'cache',
        color: 'bg-slate-200',
        label: 'Cache',
    },
    {
        objectType: 'model',
        color: 'bg-orange-200',
        label: 'Model',
    },
    {
        objectType: 'model_list',
        color: 'bg-orange-300',
        label: '[ Model ]',
    },
    {
        objectType: 'notebook',
        color: 'bg-violet-200',
        label: 'Notebook',
    },
    {
        objectType: 'question',
        color: 'bg-yellow-300',
        label: 'Question',
    },
    {
        objectType: 'results',
        color: 'bg-green-300',
        label: 'Results',
    },
    {
        objectType: 'scenario',
        color: 'bg-sky-200',
        label: 'Scenario',
    },
    {
        objectType: 'scenario_list',
        color: 'bg-sky-300',
        label: '[ Scenario ]',
    },
    {
        objectType: 'survey',
        color: 'bg-teal-300',
        label: 'Survey',
    },
    {
        objectType: 'study',
        color: 'bg-blue-200',
        label: 'Study',
    },
];

function ObjectTypeChip({ objectType }: { objectType: string }) {
    const chip = chipArray.find((chip) => chip.objectType === objectType);

    return (
        <span
            className={`${chip.color} inline-flex items-center rounded-md px-2 py-1 text-xs font-medium text-black`}
        >
            {chip.label}
        </span>
    );
}

export default ObjectTypeChip;
