export const modifiedOptions = [
    {
        id: 1,
        name: 'Today',
        value: 'today',
    },
    {
        id: 2,
        name: 'Last 7 days',
        value: 'last_7_days',
    },
    {
        id: 3,
        name: 'Last 30 days',
        value: 'last_30_days',
    },
    {
        id: 4,
        name: 'This year',
        value: 'this_year',
    },
    {
        id: 5,
        name: 'All time',
        value: 'all_time',
    },
];

export const objectTypeOptions = [
    {
        id: 1,
        name: 'Agent',
        value: 'agent',
    },
    {
        id: 2,
        name: 'Agent List',
        value: 'agent_list',
    },
    {
        id: 3,
        name: 'Cache',
        value: 'cache',
    },
    {
        id: 4,
        name: 'Model',
        value: 'model',
    },
    {
        id: 5,
        name: 'Model List',
        value: 'model_list',
    },
    {
        id: 6,
        name: 'Notebook',
        value: 'notebook',
    },
    {
        id: 7,
        name: 'Question',
        value: 'question',
    },
    {
        id: 8,
        name: 'Results',
        value: 'results',
    },
    {
        id: 9,
        name: 'Scenario',
        value: 'scenario',
    },
    {
        id: 10,
        name: 'Scenario List',
        value: 'scenario_list',
    },
    {
        id: 11,
        name: 'Study',
        value: 'study',
    },
    {
        id: 12,
        name: 'Survey',
        value: 'survey',
    },
];

export const visibilityOptions = [
    {
        id: 1,
        name: 'private',
        value: 'private',
    },
    {
        id: 2,
        name: 'public',
        value: 'public',
    },
    {
        id: 3,
        name: 'unlisted',
        value: 'unlisted',
    },
];

export const starOptions = [
    {
        id: 1,
        name: 'Starred',
        value: 'starred',
    },
    {
        id: 2,
        name: 'All content',
        value: 'all_content',
    },
];
