function Question({
    questionOptions,
    questionText,
    questionType,
}: {
    questionOptions: string[] | undefined;
    questionText: string;
    questionType: string;
}) {
    const formattedQuestionType = questionType
        .toLowerCase()
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

    return (
        <div className="p-4 border-2 dark:border-primary-dark-border rounded-lg dark:text-primary-dark-text relative">
            <h2 className="text-l font-semibold">{questionText}</h2>
            <ul className="list-disc list-inside">
                {questionOptions !== undefined &&
                    questionOptions.map((option, index) => (
                        <li key={index}>{option}</li>
                    ))}
                <div className="flex space-x-2 mt-2">
                    <div className="relative">
                        <div className="bg-pink-300 dark:bg-pink-600/50 text-white dark:text-primary-dark-text py-1 px-3 text-sm focus:outline-none transition duration-150 ease-in-out">
                            {formattedQuestionType}
                        </div>
                    </div>
                </div>
            </ul>
        </div>
    );
}

export default Question;
