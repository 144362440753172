import DataTable from './DataTable';

function ModelList({
    columns,
    records,
}: {
    columns: object;
    records: object[];
}) {
    return <DataTable columns={columns} records={records} />;
}

export default ModelList;
