export async function action() {
    alert('Stale object check has started.');
    const response = await fetch('/api/admin/check-stale-objects', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (response.ok) {
        const successData = await response.json();
        const staleObjectCount = successData.stale_objects;
        const successMessage = `Number of stale objects found: ${staleObjectCount}`;
        alert(successMessage);
        return successMessage;
    } else {
        const errorMessage = 'An error occurred checking for stale objects.';
        alert('An error occurred checking for stale objects.');
        return errorMessage;
    }
}
