import { Link } from 'react-router-dom';
import { ArrowRight, ExternalLink } from 'react-feather';

function GettingStartedIndex() {
    const linkStyles =
        'text-blue-500 hover:text-blue-600 hover:underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent';

    return (
        <div className="space-y-10">
            <div className="space-y-2">
                <h1 className="font-bold text-4xl pb-2">Get started</h1>
                <p>
                    Hi! Let's get you set up to conduct AI-powered research in
                    just a few steps!
                </p>
            </div>
            <div className="space-y-2">
                <h2 className="font-bold text-2xl">1. Install EDSL</h2>
                <p>
                    Download and install the EDSL package.{' '}
                    <Link
                        to="/getting-started/edsl-installation"
                        className={linkStyles}
                    >
                        See installation instructions
                        <ArrowRight
                            className="inline w-4 h-4 ml-1"
                            strokeWidth="1.5"
                        />
                    </Link>
                </p>
            </div>
            <div className="space-y-2">
                <h2 className="font-bold text-2xl">2. Create a Coop account</h2>
                <p>
                    Get access to special features, storage, and collaboration
                    tools.{' '}
                    <Link to="/login" className={linkStyles}>
                        Sign up for Coop
                        <ArrowRight
                            className="inline w-4 h-4 ml-1"
                            strokeWidth="1.5"
                        />
                    </Link>
                </p>
            </div>
            <div className="space-y-2">
                <h2 className="font-bold text-2xl">3. Store API keys</h2>
                <p>
                    Use your Expected Parrot API key to access hundreds of LLMs
                    at once, or bring your own keys.{' '}
                    <Link
                        to="/getting-started/edsl-api-keys"
                        className={linkStyles}
                    >
                        See instructions
                        <ArrowRight
                            className="inline w-4 h-4 ml-1"
                            strokeWidth="1.5"
                        />
                    </Link>
                </p>
            </div>
            <div className="space-y-2">
                For more examples and templates, explore our{' '}
                <a
                    href="https://docs.expectedparrot.com/en/latest/starter_tutorial.html"
                    target="_blank"
                    className={`inline-flex items-center ${linkStyles}`}
                >
                    Starter Tutorial
                    <ExternalLink
                        className="inline w-4 h-4 ml-1"
                        strokeWidth="1.5"
                    />
                </a>{' '}
                and{' '}
                <a
                    href="https://docs.expectedparrot.com/en/latest/overview.html"
                    target="_blank"
                    className={`inline-flex items-center ${linkStyles}`}
                >
                    Docs
                    <ExternalLink
                        className="inline w-4 h-4 ml-1"
                        strokeWidth="1.5"
                    />
                </a>{' '}
            </div>
        </div>
    );
}

export default GettingStartedIndex;
