import { useLoaderData } from 'react-router-dom';
import * as Avatar from '@radix-ui/react-avatar';
import { Eye, Download, Gift, Star } from 'react-feather';
import { ObjectTableData } from '../../../../types';
import ObjectTable from '../../../base/ObjectTable';
import GiftModal from './GiftModal';

interface PublicProfileData {
    is_admin: boolean;
    is_authenticated: boolean;
    is_beta_user: boolean;
    profile_photo: string;
    username: string;
    user_downloads: number;
    user_stars: number;
    user_views: number;
}

function Username() {
    const data = useLoaderData() as PublicProfileData & ObjectTableData;

    const btnBaseStyles =
        'py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white border-gray-200 hover:bg-gray-100 focus:z-10 focus:ring focus:ring-gray-100 dark:focus:ring-gray-500 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700';

    function getTableOrMessage() {
        if (data.results_status === 'no content uploaded') {
            return (
                <div className="text-2xl text-center space-y-4">
                    <p>This user doesn't have any public content 😔</p>
                    <p>Please check back later!</p>
                </div>
            );
        } else if (data.results_status === 'no results found') {
            return (
                <div className="text-2xl text-center space-y-4">
                    <p>This user doesn't have any public content 😔</p>
                    <p>Please check back later!</p>
                </div>
            );
        } else {
            return (
                <ObjectTable
                    pageType="explore"
                    objects={data.objects}
                    currentPage={data.current_page}
                    totalPages={data.total_pages}
                    isAdmin={data.is_admin}
                />
            );
        }
    }

    return (
        <>
            <div className="space-y-6">
                <div className="flex justify-between">
                    <div className="flex items-center gap-6">
                        <Avatar.Root className="shrink-0 inline-flex size-16 select-none items-center justify-center overflow-hidden rounded-full bg-green-100 align-middle">
                            <Avatar.Image
                                className="size-full rounded-full object-cover"
                                src={data.profile_photo}
                                alt="Profile photo"
                            />
                            <Avatar.Fallback
                                className="flex size-full items-center justify-center bg-green-100 text-xl leading-1 font-medium text-green-800"
                                delayMs={600}
                            >
                                {data.username
                                    ? data.username.slice(0, 2).toUpperCase()
                                    : 'AN'}
                            </Avatar.Fallback>
                        </Avatar.Root>
                        <h1 className="font-semibold text-3xl pb-2">
                            {data.username}
                        </h1>
                        <GiftModal username={data.username}>
                            <button
                                type="button"
                                className={`flex items-center rounded-lg border hover:text-blue-700 dark:hover:text-white ${btnBaseStyles}`}
                            >
                                <Gift
                                    className="inline w-4 h-4"
                                    strokeWidth="2"
                                />
                            </button>
                        </GiftModal>
                    </div>
                    <div className="flex flex-col">
                        <span>
                            <Star
                                className="inline w-4 h-4 mr-2"
                                strokeWidth="1.5"
                            />
                            {data.user_stars} stars
                        </span>
                        <span>
                            <Download
                                className="inline w-4 h-4 mr-2"
                                strokeWidth="1.5"
                            />
                            {data.user_downloads} downloads
                        </span>
                        <span>
                            <Eye
                                className="inline w-4 h-4 mr-2"
                                strokeWidth="1.5"
                            />
                            {data.user_views} views
                        </span>
                    </div>
                </div>
                {getTableOrMessage()}
            </div>
        </>
    );
}

export default Username;
