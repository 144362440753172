import KeyValueTable from './KeyValueTable';

function Scenario({ entries }: { entries: object }) {
    const data = Object.entries(entries)
        .filter(([key, value]) => key !== 'filesize')
        .map(([key, value], index) => {
            return { id: index, key: key, value: value };
        });

    return (
        <KeyValueTable
            title="Scenario data"
            keyColName="Key"
            valueColName="Value"
            data={data}
        />
    );
}

export default Scenario;
