import React from 'react';
import { NavLink } from 'react-router-dom';
import FilterRow from './FilterRow';
import SearchBar from './SearchBar';
import TypeDropdown from './TypeDropdown';

interface WrapperProps {
    children: React.ReactNode;
    owners: { id: string; username: string; is_current_user: boolean }[];
    pageType: 'home' | 'explore';
    showTabs?: boolean;
}

function ObjectTableWrapper({
    children,
    owners,
    pageType,
    showTabs = true,
}: WrapperProps) {
    const activeNavLinkStyles =
        'text-blue-600 border-blue-600 dark:text-primary-dark-text-accent dark:border-primary-dark-text-accent';
    const inactiveNavLinkStyles = 'border-gray-200';

    return (
        <>
            <div className="flex justify-between gap-x-8 mb-4">
                {showTabs && (
                    <div className="space-x-2">
                        <NavLink
                            to="/content"
                            end={true}
                            className={({ isActive }) =>
                                isActive
                                    ? `p-4 border-b-2 rounded-t-lg text-sm font-medium text-center ${activeNavLinkStyles}`
                                    : `p-4 border-b-2 rounded-t-lg text-sm font-medium text-center ${inactiveNavLinkStyles}`
                            }
                        >
                            My
                        </NavLink>
                        <NavLink
                            to="/content/explore"
                            className={({ isActive }) =>
                                isActive
                                    ? `p-4 border-b-2 rounded-t-lg text-sm font-medium text-center ${activeNavLinkStyles}`
                                    : `p-4 border-b-2 rounded-t-lg text-sm font-medium text-center ${inactiveNavLinkStyles}`
                            }
                        >
                            Explore
                        </NavLink>
                        <NavLink
                            to="/content/favorites"
                            className={({ isActive }) =>
                                isActive
                                    ? `p-4 border-b-2 rounded-t-lg text-sm font-medium text-center ${activeNavLinkStyles}`
                                    : `p-4 border-b-2 rounded-t-lg text-sm font-medium text-center ${inactiveNavLinkStyles}`
                            }
                        >
                            Starred
                        </NavLink>
                    </div>
                )}
                <div className="flex gap-x-8">
                    <SearchBar />
                    <TypeDropdown />
                </div>
            </div>
            {/* <FilterRow pageType={pageType} owners={owners} /> */}
            {children}
        </>
    );
}

export default ObjectTableWrapper;
