export async function action({ request }) {
    const formData = await request.formData();
    let intent = formData.get('intent');

    let response: Response;

    if (intent === 'update_demographics') {
        response = await fetch(`/api/update-demographics`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                age: formData.get('age'),
            }),
        });
    }

    if (response.ok) {
        return { success: true, message: 'Demographics updated successfully!' };
    } else if (response.status === 422) {
        return { success: false, message: 'Pydantic error.' };
    } else if (response.status === 504) {
        return { success: false, message: 'No response from server.' };
    } else {
        const errorData = await response.json();
        return { success: false, message: errorData.detail };
    }
}
