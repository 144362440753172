interface CodeBlockProps {
    children: React.ReactNode;
}

function CodeBlock({ children }: CodeBlockProps) {
    return (
        <div className="p-2 bg-gray-100 dark:bg-slate-950 font-mono overflow-x-auto whitespace-nowrap">
            {children}
        </div>
    );
}

export default CodeBlock;
