import { redirect } from 'react-router-dom';

export async function action({ request, params }) {
    const data = await request.json();
    const intent = data.intent;
    const questions = JSON.parse(data.questions);

    let response: Response;

    if (intent === 'get_code') {
        response = await fetch(`/api/survey-code`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ questions }),
        });
    } else if (intent === 'edit_object') {
        response = await fetch(`/api/edit-survey-object`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ questions, survey_uuid: params.surveyId }),
        });
    }

    if (response.ok && intent === 'get_code') {
        const successData = await response.json();
        return successData.code;
    } else if (response.ok) {
        const successData = await response.json();
        const objectId = successData.uuid;
        return redirect(`/content/${objectId}`);
    } else if (response.status === 401) {
        return redirect('/login');
    } else if (response.status === 422) {
        const errorData = await response.json();
        return 'Pydantic error.';
    } else if (response.status === 504) {
        return 'No response from server.';
    } else {
        const errorData = await response.json();
        return errorData.detail;
    }
}
