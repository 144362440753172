import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import Message from './Message';

function ErrorPage() {
    const error = useRouteError();

    if (isRouteErrorResponse(error)) {
        const title = `HTTP ${error.status}`;
        const message = error.data.message;
        return <Message title={title} mainMessage={message} />;
    }

    // Rethrow to the global error page if this error boundary cannot handle it
    throw error;
}

export default ErrorPage;
