import KeyValueTable from './KeyValueTable';

function Agent({ traits }: { traits: object }) {
    const data = Object.entries(traits).map(([trait, value], index) => {
        return { id: index, key: trait, value: value };
    });

    return (
        <KeyValueTable
            title="Agent traits"
            keyColName="Trait"
            valueColName="Value"
            data={data}
        />
    );
}

export default Agent;
