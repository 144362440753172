import { json, redirect } from 'react-router-dom';

export async function loader() {
    const response = await fetch('/api/home-auth', {
        method: 'GET',
    });

    if (response.ok) {
        return response;
    } else if (response.status === 401) {
        return redirect('/login');
    } else if (response.status === 504) {
        throw json(
            { message: 'No response from server.' },
            { status: response.status }
        );
    } else {
        const errorData = await response.json();
        if (response.status === 400 && errorData.message === 'No username') {
            return redirect('/select-username');
        } else {
            throw json(
                { message: errorData.detail },
                { status: response.status }
            );
        }
    }
}
