import { redirect } from 'react-router-dom';

export async function action({ request }) {
    const formData = await request.formData();
    let intent = formData.get('intent');

    let response: Response;

    if (intent === 'update_username') {
        const username = formData.get('username');
        response = await fetch('/api/update-username', {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username }),
        });
    } else if (intent === 'toggle_beta') {
        response = await fetch('/api/update-beta-status', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                value: formData.get('value'),
            }),
        });
    } else if (intent === 'delete_account') {
        response = await fetch('/api/home/delete-account', {
            method: 'DELETE',
        });
    } else if (intent === 'update_profile_photo') {
        response = await fetch('/api/update-profile-photo', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                base64Image: formData.get('base64Image'),
            }),
        });
    }

    if (response.ok && intent === 'delete_account') {
        return redirect('/goodbye');
    } else if (response.ok) {
        const successData = await response.json();
        return { success: true, message: successData.message };
    } else if (response.status === 422) {
        return { success: false, message: 'Pydantic error.' };
    } else if (response.status === 504) {
        return { success: false, message: 'No response from server.' };
    } else {
        const errorData = await response.json();
        return { success: false, message: errorData.detail };
    }
}
