import { Link } from 'react-router-dom';
import CodeBlock from '../../CodeBlock';

function CoopRemoteCache() {
    const linkStyles =
        'text-blue-500 hover:text-blue-600 hover:underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent';

    const remoteCachingToggleImageURL = new URL(
        '../../../../../public/assets/getting-started/remote-caching-toggle.png',
        import.meta.url
    ).pathname;
    const remoteCachingPageImageURL = new URL(
        '../../../../../public/assets/getting-started/remote-caching-page.png',
        import.meta.url
    ).pathname;
    const remoteCachingEntriesImageURL = new URL(
        '../../../../../public/assets/getting-started/remote-caching-entries.png',
        import.meta.url
    ).pathname;

    return (
        <>
            <div className="space-y-2">
                <h1 className="font-bold text-4xl pb-2">Coop: Remote cache</h1>
                <p className="text-lg">
                    You can cache your LLM calls remotely on Coop.
                </p>
                <br />
                <h2 className="font-bold text-2xl pb-2">
                    1. Activate remote caching
                </h2>
                <p>
                    Start off by storing your Expected Parrot API key (see the
                    tutorial on the{' '}
                    <Link
                        to="/getting-started/coop-how-it-works"
                        className={linkStyles}
                    >
                        How it works
                    </Link>{' '}
                    page.)
                </p>
                <p>
                    Then log in to Coop and go to the{' '}
                    <Link to="/home/api" className={linkStyles}>
                        API settings page
                    </Link>
                    , and turn on the remote caching toggle:
                </p>
                <br />
                <img
                    src={remoteCachingToggleImageURL}
                    alt="Screenshot of the Coop API page. The remote caching toggle has been turned on"
                    className="max-w-xl mx-auto"
                />
                <br />
                <h2 className="font-bold text-2xl pb-2">
                    2. Run a job with EDSL
                </h2>
                <p>
                    Now that you've activated remote caching, your results will
                    be cached to our servers automatically.
                </p>
                <p>
                    We provide an example job below. Note that we use an empty
                    in-memory cache for demonstration purposes, but the code can
                    also be used with an existing local cache.
                </p>
                <br />
                <CodeBlock>
                    from edsl import Cache, Survey
                    <br />
                    from edsl.questions import QuestionMultipleChoice,
                    QuestionFreeText
                    <br />
                    <br />
                    survey = Survey(questions=[QuestionMultipleChoice.example(),
                    <br />
                    QuestionFreeText.example()])
                    <br />
                    <br />
                    result = survey.run(cache=Cache(),
                    <br />
                    remote_cache_description="Example survey #1")
                </CodeBlock>
                <br />
                <h2 className="font-bold text-2xl pb-2">
                    3. Inspect your cache on Coop
                </h2>
                <p>
                    Go to the{' '}
                    <Link to="/home/remote-cache" className={linkStyles}>
                        remote cache page
                    </Link>
                    . You should see that your entries have been cached
                    successfully.
                </p>
                <br />
                <img
                    src={remoteCachingPageImageURL}
                    alt="Screenshot of the Coop remote cache page. There are 2 entries in the cache"
                    className="max-w-xl mx-auto"
                />
                <br />
                <p>
                    If you see more than 2 uploaded entries in your logs, then
                    your local cache might already have contained some entries.
                </p>
                <br />
                <h2 className="font-bold text-2xl pb-2">
                    4. Manage your cache entries
                </h2>
                <p>
                    You can click on{' '}
                    <span className="font-bold">View entries</span> to view and
                    delete your cache entries.
                </p>
                <br />
                <img
                    src={remoteCachingEntriesImageURL}
                    alt="Screenshot of the Coop remote cache entries page, showing that you can inspect the code of your entries and delete them"
                    className="max-w-xl mx-auto"
                />
                <br />
            </div>
        </>
    );
}

export default CoopRemoteCache;
