import { Link, useOutletContext } from 'react-router-dom';
import ContentContainer from '../base/ContentContainer';
import EPLogo from './EPLogo';

function AppIndex() {
    const isDarkMode: boolean = useOutletContext();

    return (
        <div className="h-full w-full flex flex-col grow items-stretch justify-start">
            <div className="h-full flex flex-col overflow-y-auto">
                <div className="w-full flex flex-col items-center text-center space-y-3 px-4 pt-6 pb-16">
                    <EPLogo
                        isDarkMode={isDarkMode}
                        className="w-52 mt-10 mb-10"
                    />
                    <h1 className="text-5xl sm:text-6xl font-bold text-gray-500 dark:text-primary-dark-text drop-shadow-sm">
                        Expected Parrot
                    </h1>
                    <p className="text-4xl sm:text-4xl font-bold text-green-700 dark:text-primary-dark-text drop-shadow-sm pt-4 pb-12">
                        AI-powered research
                    </p>
                    <Link
                        to="/getting-started"
                        className="bg-green-700 hover:bg-green-800 border-2 border-green-700 hover:border-green-800 rounded-lg px-10 py-2 text-xl sm:text-2xl text-white font-semibold"
                    >
                        Get started
                    </Link>
                </div>
                <div className="w-full px-4 py-16 bg-green-50 dark:bg-[#272c35]">
                    <h2 className="text-4xl text-center font-bold text-gray-700 dark:text-primary-dark-text mb-12">
                        Features
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                        <div className="flex flex-col p-6">
                            <h3 className="text-xl font-semibold text-green-700 dark:text-primary-dark-text-accent mb-3">
                                Simplified access
                            </h3>
                            <p className="text-gray-600 dark:text-gray-300">
                                Access hundreds of popular language models with
                                a single key.
                            </p>
                        </div>
                        <div className="flex flex-col p-6">
                            <h3 className="text-xl font-semibold text-green-700 dark:text-primary-dark-text-accent mb-3">
                                Integrate data
                            </h3>
                            <p className="text-gray-600 dark:text-gray-300">
                                Extend your existing surveys and user data with
                                AI.
                            </p>
                        </div>
                        <div className="flex flex-col p-6">
                            <h3 className="text-xl font-semibold text-green-700 dark:text-primary-dark-text-accent mb-3">
                                Collaborate seamlessly
                            </h3>
                            <p className="text-gray-600 dark:text-gray-300">
                                A platform for creating, storing and sharing AI
                                projects.
                            </p>
                        </div>
                        <div className="flex flex-col p-6">
                            <h3 className="text-xl font-semibold text-green-700 dark:text-primary-dark-text-accent mb-3">
                                Human-AI workflows
                            </h3>
                            <p className="text-gray-600 dark:text-gray-300">
                                Synthesize responses from humans and AI to
                                augment your surveys.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="w-full px-4 py-16">
                    <h2 className="text-4xl text-center font-bold text-gray-700 dark:text-primary-dark-text mb-12">
                        How it works
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                        <div className="flex flex-col p-6">
                            <h3 className="text-xl font-semibold text-green-700 dark:text-primary-dark-text-accent mb-3">
                                Create surveys
                            </h3>
                            <p className="text-gray-600 dark:text-gray-300">
                                Use questions to conduct research and
                                experiments.
                            </p>
                        </div>
                        <div className="flex flex-col p-6">
                            <h3 className="text-xl font-semibold text-green-700 dark:text-primary-dark-text-accent mb-3">
                                Design agents
                            </h3>
                            <p className="text-gray-600 dark:text-gray-300">
                                Create AI personas to answer your questions.
                            </p>
                        </div>
                        <div className="flex flex-col p-6">
                            <h3 className="text-xl font-semibold text-green-700 dark:text-primary-dark-text-accent mb-3">
                                Choose models
                            </h3>
                            <p className="text-gray-600 dark:text-gray-300">
                                Choose language models to generate responses.
                            </p>
                        </div>
                        <div className="flex flex-col p-6">
                            <h3 className="text-xl font-semibold text-green-700 dark:text-primary-dark-text-accent mb-3">
                                Analyze results
                            </h3>
                            <p className="text-gray-600 dark:text-gray-300">
                                Access built-in tools to analyze and share
                                results.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="w-full px-4 py-16 bg-green-50 dark:bg-[#272c35]">
                    <h2 className="text-4xl text-center font-bold text-gray-700 dark:text-primary-dark-text mb-12">
                        Use cases
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        <div className="flex flex-col p-6">
                            <h3 className="text-xl font-semibold text-green-700 dark:text-primary-dark-text-accent mb-3">
                                Market research
                            </h3>
                            <p className="text-gray-600 dark:text-gray-300">
                                Test your ideas and simulate feedback with AI.
                            </p>
                        </div>
                        <div className="flex flex-col p-6">
                            <h3 className="text-xl font-semibold text-green-700 dark:text-primary-dark-text-accent mb-3">
                                Data labeling
                            </h3>
                            <p className="text-gray-600 dark:text-gray-300">
                                Extract insights and information from your data.
                            </p>
                        </div>
                        <div className="flex flex-col p-6">
                            <h3 className="text-xl font-semibold text-green-700 dark:text-primary-dark-text-accent mb-3">
                                Hybrid surveys
                            </h3>
                            <p className="text-gray-600 dark:text-gray-300">
                                Integrate responses from humans and AI to extend
                                your data.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AppIndex;
