export async function action({ request }) {
    const formData = await request.formData();
    let intent = formData.get('intent');

    const entryId = formData.get('entry_uuid');

    let response: Response;

    if (intent === 'delete_entry') {
        response = await fetch(`/api/remote-cache/entries/${entryId}`, {
            method: 'DELETE',
        });
    }

    if (response.ok) {
        const successData = await response.json();
        return successData.message;
    } else if (response.status === 422) {
        return 'Pydantic error.';
    } else if (response.status === 504) {
        return 'No response from server.';
    } else {
        const errorData = await response.json();
        return errorData.detail;
    }
}
