import { Plus, Trash2 } from 'react-feather';
import { JobState } from './types';

function ConfigureAgentsForm({
    jobState,
    addAgent,
    removeAgent,
    updateAgentTrait,
}: {
    jobState: JobState;
    addAgent: () => void;
    removeAgent: (id: string) => void;
    updateAgentTrait: (id: string, traitId: string, value: string) => void;
}) {
    return (
        <div className="space-y-4">
            {jobState.agents.map((agent, index) => (
                <div
                    key={agent.id}
                    className="p-4 border rounded-lg dark:border-gray-600"
                >
                    <div className="flex justify-between items-center mb-2">
                        <h4 className="font-medium">Agent #{index + 1}</h4>
                        <button
                            onClick={() => removeAgent(agent.id)}
                            className="p-1 text-red-600 hover:text-red-800 dark:text-red-400 dark:hover:text-red-300"
                            aria-label="Delete agent"
                        >
                            <Trash2 className="w-4 h-4" />
                        </button>
                    </div>
                    {Object.entries(agent.traits).map(
                        ([traitId, traitValue]) => {
                            const trait = jobState.traits.find(
                                (t) => t.id === traitId
                            );
                            return (
                                <div key={traitId} className="mb-2">
                                    <label
                                        htmlFor={`${agent.id}-${trait.id}`}
                                        className="block text-sm font-medium mb-1"
                                    >
                                        {trait.name || 'unnamed trait'}
                                    </label>
                                    <input
                                        id={`${agent.id}-${trait.id}`}
                                        type="text"
                                        value={traitValue}
                                        onChange={(e) =>
                                            updateAgentTrait(
                                                agent.id,
                                                traitId,
                                                e.target.value
                                            )
                                        }
                                        className="w-full p-2 text-sm bg-gray-50 focus:outline-none border rounded-lg border-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700/20 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:placeholder-gray-400"
                                    />
                                </div>
                            );
                        }
                    )}
                </div>
            ))}
            <div className="flex items-center space-x-2">
                <button
                    onClick={addAgent}
                    className="flex items-center px-4 py-2.5 hover:bg-gray-300/20 hover:transition-colors border border-gray-300 dark:border-2 dark:border-gray-500 rounded-md font-medium text-sm"
                >
                    <Plus className="w-4 h-4 mr-1" />
                    Add new agent
                </button>
            </div>
        </div>
    );
}

export default ConfigureAgentsForm;
