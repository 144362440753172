import { json, redirect } from 'react-router-dom';

export async function action({ request, params }) {
    const data = await request.json();
    const responses = data.responses;
    const response = await fetch(`/api/respond/${params.projectId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ responses }),
    });
    if (response.ok) {
        return redirect('/end-of-survey');
    } else if (response.status === 401) {
        return redirect('/login');
    } else if (response.status === 403) {
        throw json(
            { message: 'You are not authorized to access this project.' },
            { status: response.status }
        );
    } else if (response.status === 504) {
        throw json(
            { message: 'No response from server.' },
            { status: response.status }
        );
    } else {
        const errorData = await response.json();
        throw json({ message: errorData.detail }, { status: response.status });
    }
}
