import { useFetcher } from 'react-router-dom';

function DeleteUserForm({ email }: { email: string }) {
    const fetcher = useFetcher();

    return (
        <fetcher.Form method="post">
            <input type="hidden" name="email" value={email} />
            <button
                name="intent"
                value="delete_user"
                className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:shadow-outline"
            >
                Delete
            </button>
        </fetcher.Form>
    );
}

export default DeleteUserForm;
