import { useFetcher } from 'react-router-dom';

function ResetAPIKeyForm() {
    const fetcher = useFetcher();

    return (
        <fetcher.Form method="post">
            <button
                name="intent"
                value="reset"
                className="px-6 py-2.5 bg-yellow-600 hover:bg-yellow-800 rounded-md text-white font-semibold"
            >
                Reset
            </button>
        </fetcher.Form>
    );
}
export default ResetAPIKeyForm;
