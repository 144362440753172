import { useCopyToClipboard } from '../../../../hooks/useCopyToClipboard';

function APIKey({ apiKey }: { apiKey: string }) {
    const [isCopied, copy] = useCopyToClipboard();

    function handleCopy(text: string) {
        copy(text).catch((error) => {
            console.error('Failed to copy!', error);
        });
    }

    return (
        <>
            <input
                type="text"
                value={apiKey}
                readOnly
                className="bg-white focus:outline-none border border-gray-400 rounded-l-md focus:ring-1 focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700/20 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
            <button
                type="button"
                onClick={() => handleCopy(apiKey)}
                className="w-20 mr-4 py-2.5 bg-green-600 hover:bg-green-700 rounded-r-md text-white font-semibold"
            >
                {isCopied === false ? 'Copy' : 'Copied!'}
            </button>
        </>
    );
}

export default APIKey;
