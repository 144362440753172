import { Outlet } from 'react-router-dom';
import ContentContainer from '../base/ContentContainer';
import Sidebar from './Sidebar';

function GettingStarted() {
    return (
        <div className="h-full w-full flex">
            <Sidebar />
            <ContentContainer>
                <Outlet />
            </ContentContainer>
        </div>
    );
}

export default GettingStarted;
