function Notebook({ notebookContent }: { notebookContent: string }) {
    return (
        <div
            className="bg-white"
            dangerouslySetInnerHTML={{
                __html: notebookContent,
            }}
        />
    );
}

export default Notebook;
