import * as Dialog from '@radix-ui/react-dialog';
import { Link, useFetcher } from 'react-router-dom';
import { AlertTriangle, Check, X } from 'react-feather';
import { ActionResponse } from '../../../../types';

function GiftSuccessAlert({ message }: { message: React.ReactNode }) {
    return (
        <div className="flex items-center border px-4 py-3 rounded bg-green-50 border-green-400 text-green-800">
            <Check className="inline w-4 h-4 mr-2" />
            {message}
        </div>
    );
}

function GiftErrorAlert({ message }: { message: React.ReactNode }) {
    return (
        <div className="flex items-center border px-4 py-3 rounded bg-red-50 border-red-400 text-red-800">
            <AlertTriangle className="inline w-4 h-4 mr-2" />
            {message}
        </div>
    );
}

function GiftActionAlert({ response }: { response: ActionResponse | null }) {
    if (!response) return null;

    const { success, message } = response;

    if (success) {
        return <GiftSuccessAlert message={message} />;
    } else if (message.includes("don't have enough credits")) {
        const updatedMessage = (
            <span>
                {message} Go to the{' '}
                <Link className="underline" to="/home/purchases">
                    Credits page
                </Link>{' '}
                to purchase more.
            </span>
        );
        return <GiftErrorAlert message={updatedMessage} />;
    } else {
        return <GiftErrorAlert message={message} />;
    }
}

function GiftModal({
    username,
    children,
}: {
    username: string;
    children: React.ReactNode;
}) {
    const fetcher = useFetcher();

    return (
        <Dialog.Root>
            <Dialog.Trigger asChild>{children}</Dialog.Trigger>
            <Dialog.Portal>
                <Dialog.Overlay className="fixed inset-0 bg-black/60 animate-dialog-overlay-show" />
                <Dialog.Content className="w-[90vw] max-w-[450px] max-h-[85vh] fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-6 bg-white dark:bg-gray-700 rounded-md focus:outline-none animate-dialog-content-show">
                    <Dialog.Title asChild>
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Gift {username} credits
                        </h3>
                    </Dialog.Title>
                    <div className="text-sm py-4 md:py-5">
                        <fetcher.Form method="post">
                            <div>
                                <label
                                    htmlFor="credits-gifted-input"
                                    className="block mb-3 text-base font-medium text-gray-900 dark:text-white"
                                >
                                    Credit amount
                                </label>
                                <input
                                    type="text"
                                    name="credits_gifted"
                                    id="credits-gifted-input"
                                    className="block w-full p-2.5 mb-3 bg-gray-50 focus:outline-none border rounded-lg border-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700/20 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:placeholder-gray-400"
                                    required
                                />
                                <label
                                    htmlFor="gift-note-input"
                                    className="block mb-3 text-base font-medium text-gray-900 dark:text-white"
                                >
                                    Note
                                </label>
                                <textarea
                                    rows={3}
                                    name="gift_note"
                                    id="gift-note-input"
                                    className="block resize-none w-full p-2.5 mb-3 bg-gray-50 focus:outline-none border rounded-lg border-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700/20 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:placeholder-gray-400"
                                    placeholder="Add a note to your gift (optional)"
                                />
                                <input
                                    type="hidden"
                                    name="recipient_username"
                                    value={username}
                                />
                            </div>
                            <button
                                type="submit"
                                name="intent"
                                value="gift"
                                className="w-full mb-3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            >
                                Gift
                            </button>
                        </fetcher.Form>
                        <GiftActionAlert response={fetcher.data} />
                    </div>

                    <Dialog.Close asChild>
                        <button
                            className="absolute top-2.5 right-2.5 h-6 w-6 inline-flex justify-center align-center rounded-full"
                            aria-label="Close"
                        >
                            <X className="inline w-4 h-4" strokeWidth="2" />
                        </button>
                    </Dialog.Close>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
}

export default GiftModal;
