export async function loader({ request }) {
    const currentUrl = new URL(request.url);
    const token = currentUrl.searchParams.get('token');
    const email = currentUrl.searchParams.get('email');

    const targetURL = new URL(`${currentUrl.origin}/api/verify-email`);
    targetURL.searchParams.append('token', token);
    targetURL.searchParams.append('email', email);

    const response = await fetch(targetURL, {
        method: 'GET',
    });

    if (response.ok) {
        return response;
    } else if (response.status === 504) {
        return { message: 'No response from server.' };
    } else {
        const errorData = await response.json();
        return { message: errorData.detail };
    }
}
