import { useState } from 'react';
import { Form } from 'react-router-dom';
import { Search } from 'react-feather';

function SearchBar() {
    const [isExpanded, setIsExpanded] = useState(false);

    function handleFocus() {
        setIsExpanded(true);
    }
    function handleBlur() {
        setIsExpanded(false);
    }

    return (
        <Form className="flex justify-center">
            <div className="relative flex">
                <input
                    name="search_query"
                    type="search"
                    className={`transition-[width] motion-reduce:transition-none duration-300 ease-in-out ${
                        isExpanded ? 'w-96' : 'w-40'
                    } h-full block p-2.5 text-sm text-gray-900 bg-gray-50 border rounded-lg border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500`}
                    placeholder="Search..."
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    required
                />
                <button
                    type="submit"
                    aria-label="Search"
                    className="h-full absolute top-0 end-0 px-4 py-2.5 text-sm font-medium text-white bg-blue-500 hover:bg-blue-600 rounded-r-lg border-blue-500 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-800"
                >
                    <Search className="w-4 h-4" />
                </button>
            </div>
        </Form>
    );
}

export default SearchBar;
